import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import Footer from "../../component/Footer";
import book_1 from "../assets/images/book_1.svg";
import { useTranslation } from "react-i18next";
import Params from "../config/Params";
import ApiConfig from "../api/ApiConfig";
import axios from "axios";
import search_icon from "../assets/images/search_icon.svg";
import cross_icon from "../assets/images/masterdatapic/cross.png";
import snd_icon from "../assets/images/com_send_btn.svg";

export default function SearchBook() {
  const location = useLocation();
  const { t } = useTranslation();
  const [searchResultss, setSearchResultss] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [ser, setSer] = useState("");
  const [isRequested, setIsRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bookque, setBookQue] = useState([]);
  const [search, setSearch] = useState([]);
  const [mybooks, setMybooks] = useState([]);
  const [bookHistory, setBookHistory] = useState([]);
  const [myQueue, setMyQueue] = useState([]);
  const [searchBook, setSearchBook] = useState([]);
  const [messages, setMessages] = useState("");
  const [isCrossIconActive, setIsCrossIconActive] = useState(true); // Initial state

  const toggleIcon = () => {
    setIsCrossIconActive(!isCrossIconActive); // Toggle the state
  };

  // Extract search results from state passed through routing
  const { searchResults, searchQuery } = location.state || {};
  useEffect(() => {
    if (searchQuery) {
      setSer(searchQuery); // Set the search input to the query value from previous page
    }
    if (searchResults) {
      setSearchResultss(searchResults);
      setShowSearchResults(true);
    }
  }, [location.state]);
  const searchAPI = async () => {
    axios(ApiConfig.SEARCH_BOOK, {
      method: "POST",
      data: {
        teacher_id: Params.teacher_id,
        search: search,
      },
      headers: {
        Accept: "application/json",
        api_key: Params.api_key,
        customer_id: Params.customer_id,
      },
    })
      .then((response) => {
        if (response.data.result) {
          setSearchBook(response.data.list);
          console.log(response.data, "this is search data");
        }
      })
      .catch((err) => {
        console.log("home error: ", err);
      });
  };
  const myQueueAPI = async (bookId, status) => {
    axios(ApiConfig.MY_QUEUE, {
      method: "POST",
      data: {
        teacher_id: Params.teacher_id,
        // search: "",
        book_id: bookId,
        status: status,
      },
      headers: {
        Accept: "application/json",
        api_key: Params.api_key,
        customer_id: Params.customer_id,
      },
    })
      .then((response) => {
        if (response.data.result) {
          setMyQueue(response.data.list);
          console.log(response.data, "this is queue data");
        } else {
          setMessages(response.data.message);
          console.log(response.data.message, "ver");
        }
      })
      .catch((err) => {
        console.log("home error: ", err);
      });
  };
  //
  const handleRequestToggle = (book_id, isRequested) => {
    setIsLoading(true); // Start loading animation

    // Simulate a request (for example, calling an API)
    setTimeout(() => {
      const status = isRequested ? "queued" : "queue"; // Toggle status
      myQueueAPI(book_id, status); // Pass book_id and status to MyBooksQueue
      setIsRequested(!isRequested); // Toggle between Request and Cancel
      setIsLoading(false); // Stop loading animation
      searchAPI();
    }, 3000); // Simulating a delay of 2 seconds
  };

  const resultsToDisplay = showSearchResults ? searchResultss : searchResults;
  return (
    <div class="library announcementnew">
      <div className="main-section">
        <div className="row">
          <div className="col-md-12">
            <div className="announcement-top">
              <label></label>
              <div className="search-wrapper">
                <input
                  type="search"
                  id="search-form"
                  className="search-input"
                  placeholder="search"
                  value={ser}
                  // onChange={(e) => setSearchQuery(e.target.value)}
                  onChange={(e) => {
                    setSer(e.target.value);
                    console.log(e.target.value, "Serach");
                  }}
                />
                <img
                  src={search_icon}
                  alt=""
                  data-mdb-ripple-init
                  onClick={searchAPI}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="books-main-wrapper">
                <div className="my-books">
                  <div className="row">
                    {resultsToDisplay?.length > 0 ? (
                      <>
                        {resultsToDisplay.map((postDetail, index) => (
                          <div
                            className="col-md-6 col-lg-4 col-sm-12"
                            key={index}
                          >
                            <div className="book-card">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="bc-img-box">
                                    <img
                                      className=""
                                      src={
                                        postDetail.book_image
                                          ? ApiConfig.NODE_URL +
                                            "/" +
                                            postDetail.book_image
                                          : book_1
                                      }
                                      alt="Description"
                                      style={{
                                        // height: "100px",
                                        // width: "100px",
                                        border: "none",
                                        boxShadow: "none",
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-8">
                                  <p className="bc-title">
                                    {postDetail.book_name}
                                  </p>
                                  <p className="bc-author">
                                    By : {postDetail.author_name}
                                    <span
                                      className="libraryfine"
                                      style={{ color: "blue" }}
                                    >
                                      {postDetail.book_available}/
                                      {postDetail.total_book}
                                    </span>
                                  </p>
                                  <div className="d-flex justify-content-between align-items">
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        color:
                                          Number(postDetail.book_available) ===
                                          0
                                            ? "red"
                                            : "green",
                                      }}
                                    >
                                      {Number(postDetail.book_available) === 0
                                        ? `Not Available`
                                        : `Available`}
                                    </p>

                                    {/* Show request button only when book_available is 0 */}
                                    {Number(postDetail.book_available) ===
                                      0 && (
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          color: "green",
                                        }}
                                      >
                                        {/* <button
                                            className="req"
                                            onClick={() =>
                                              handleRequestToggle(
                                                postDetail.book_id,
                                                isRequested
                                              )
                                            }
                                            disabled={isLoading} // Disable button during loading
                                          >
                                          </button> */}
                                        {isCrossIconActive ? (
                                          <img
                                            src={cross_icon}
                                            alt=""
                                            style={{
                                              width: "15px",
                                              height: "15px",
                                            }}
                                            onClick={() => {
                                              toggleIcon();
                                              handleRequestToggle(
                                                postDetail.book_id,
                                                true
                                              );
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={snd_icon}
                                            alt=""
                                            style={{
                                              width: "18px",
                                              height: "18px",
                                            }}
                                            onClick={() => {
                                              toggleIcon();
                                              handleRequestToggle(
                                                postDetail.book_id,
                                                false
                                              );
                                            }}
                                          />
                                        )}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <p>No results found</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
