import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Params from "../../config/Params";
import axios from "axios";
import ApiConfig from "../../api/ApiConfig";
import { toast } from "react-toastify";
import { IoIosClose } from "react-icons/io";

export const FeedEdit = () => {
  const [feedDetail, setFeedDetail] = useState({
    feed_title: "",
    feed_description: "",
    feed_id: null,
    feed_attachment: [],
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const location = useLocation();
  const item = location.state;
  const navigation = useNavigate();

  useEffect(() => {
    feedDetailsItem();
  }, []);

  const feedDetailsItem = () => {
    axios(ApiConfig.FEED_VIEW, {
      method: "GET",
      params: {
        feed_id: item,
        user_id: Params.teacher_id,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
        api_key: Params.api_key,
        customer_id: Params.customer_id,
      },
    })
      .then((response) => {
        if (response.data.result) {
          setFeedDetail(response.data.data[0]);
        }
      })
      .catch((error) => {
        console.error("API call error:", error);
      });
  };

  const editFeed = async (event) => {
    event.preventDefault();

    if (!feedDetail.feed_title.trim()) {
      alert("Please enter a title!");
      return;
    }
    if (!feedDetail.feed_description.trim()) {
      alert("Please enter a description!");
      return;
    }

    const formData = new FormData();
    formData.append("teacher_id", Params.teacher_id);
    formData.append("feed_title", feedDetail.feed_title);
    formData.append("feed_description", feedDetail.feed_description);
    formData.append("feed_id", feedDetail.feed_id);

    // Include new files
    selectedFiles.forEach((file, index) => {
      formData.append(`feed_attachment[${index}]`, file);
    });

    // Include remaining files (after deletion)
    feedDetail.feed_attachment.forEach((file, index) => {
      formData.append(
        `existing_attachments[${index}]`,
        file.attachment_feed_file,
      );
    });

    try {
      const response = await axios({
        url: ApiConfig.FEED_EDIT,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
          customer_id: Params.customer_id,
          api_key: Params.api_key,
        },
      });

      if (response.data.result) {
        toast.success("Feed updated successfully!");
        navigation("/Feed");
      } else {
        console.error("Update failed:", response.data.message);
        toast.error("Failed to update the feed.");
      }
    } catch (error) {
      console.error("Error updating feed:", error);
      alert("An error occurred while updating the feed.");
    }
  };

  const handleFileChange = (e) => {
    setSelectedFiles([...selectedFiles, ...e.target.files]);
  };

  // const handleDeleteExisting = (index) => {
  //   const updatedAttachments = feedDetail.feed_attachment.filter(
  //     (_, i) => i !== index,
  //   );
  //   setFeedDetail((prev) => ({
  //     ...prev,
  //     feed_attachment: updatedAttachments,
  //   }));
  // };

  const handleDeleteNew = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const renderExistingAttachments = () => {
    if (
      !feedDetail.feed_attachment ||
      feedDetail.feed_attachment.length === 0
    ) {
      return <p>No existing attachments.</p>;
    }

    return feedDetail.feed_attachment.map((file, index) => {
      const fileUrl = `${ApiConfig.NODE_URL}/${file.attachment_feed_file}`;
      const fileType = file.attachment_feed_file.split(".").pop().toLowerCase();

      const renderFile = () => {
        if (["jpg", "jpeg", "png", "gif"].includes(fileType)) {
          return (
            <img
              src={fileUrl}
              alt={`Attachment ${index}`}
              width="200"
              height="200"
            />
          );
        } else if (["mp4", "mov"].includes(fileType)) {
          return (
            <video
              width="200"
              height="200"
              controls
              style={{ position: "absolute" }}
            >
              <source src={fileUrl} />
              Your browser does not support the video tag.
            </video>
          );
        } else if (fileType === "pdf") {
          return (
            <iframe
              src={fileUrl}
              width="200"
              height="200"
              title={`Attachment ${index}`}
            ></iframe>
          );
        } else {
          return (
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
              Download File
            </a>
          );
        }
      };

      return (
        <div
          key={index}
          style={{
            marginBottom: "20px",
            display: "flex",
          }}
        >
          <div style={{ height: "200px" }}>
            {renderFile()}
            {/* <button
              onClick={() => handleDeleteExisting(index)}
              style={{
                padding: "5px 10px",
                backgroundColor: "red",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                position: "absolute",
                left: "13px",
              }}
            >
              <IoIosClose />
            </button> */}
          </div>
        </div>
      );
    });
  };

  const renderNewAttachments = () => {
    return selectedFiles.map((file, index) => (
      <div key={index} style={{ marginBottom: "20px" }}>
        <p>{file.name}</p>
        <button
          onClick={() => handleDeleteNew(index)}
          style={{
            marginTop: "10px",
            padding: "5px 10px",
            backgroundColor: "red",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Remove
        </button>
      </div>
    ));
  };

  return (
    <div>
      <div className="CreateDiary">
        <div className="main-sectionn">
          <div className="row">
            <div className="col-md-12">
              <div className="announcementCreate">
                <label className="labelCreate">Edit Feed</label>
                <form>
                  <div className="form-group">
                    <label className="contentHeader">Title</label>
                    <textarea
                      className="createInput form-control"
                      value={feedDetail.feed_title}
                      onChange={(e) =>
                        setFeedDetail((prev) => ({
                          ...prev,
                          feed_title: e.target.value,
                        }))
                      }
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label className="contentHeader">Description</label>
                    <textarea
                      className="form-control createInputt"
                      rows="4"
                      value={feedDetail.feed_description}
                      onChange={(e) =>
                        setFeedDetail((prev) => ({
                          ...prev,
                          feed_description: e.target.value,
                        }))
                      }
                    ></textarea>
                  </div>

                  <div
                    className="form-group"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label className="contentHeader">
                      Existing Attachments
                    </label>
                    {renderExistingAttachments()}

                    <label
                      className="contentHeader"
                      style={{ marginTop: "20px" }}
                    >
                      New Attachments
                    </label>
                    {renderNewAttachments()}
                  </div>
                  <div>
                    <input
                      type="file"
                      multiple
                      onChange={handleFileChange}
                      style={{
                        background: "transparent",
                        border: "1px solid #cfd2d4",
                        padding: "10px",
                      }}
                    />
                  </div>
                  <div className="form-group SubmitDiv">
                    <button
                      type="submit"
                      className="submitBut"
                      onClick={editFeed}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
