import React, { useEffect, useState } from "react";
import "../../assets/css/studentlist.css";
import { HiMagnifyingGlassPlus } from "react-icons/hi2";
import { FaUserXmark } from "react-icons/fa6";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { FaUserLock } from "react-icons/fa6";
import { FaUserEdit } from "react-icons/fa";
import { FaUserAltSlash } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import profilePic from "../../assets/images/masterdatapic/profilePic.png";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import FileSaver from "file-saver";
function Teachers() {
  const [teachername, setTeacherName] = useState("");
  const [teacheremail, setTeacherEmail] = useState("");
  const [contactnumber, setContactNumber] = useState("");
  const [teacherListapi, setTeacherListapi] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState("");
  const [showTeacherId, setShowTeacherId] = useState("");
  const [showTeacherDelete, setShowTeacherDelete] = useState("");
  const [showBlock, setShowBlock] = useState("");
  const [showBlockImage, setshowBlockImage] = useState("");
  const [showBlockResponse, setshowBlockResponse] = useState("");
  const [showUnBlockResponse, setshowUnBlockResponse] = useState("");
  const [showResign, setShowResign] = useState("");
  const clearFields = () => {
    setTeacherName("");
    setTeacherEmail("");
    setContactNumber("");
  };

  const handlePageChange = () => {
    if (nextPage != false) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (nextPage !== false) {
      setCurrentPage(currentPage + 1);
    }
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showunblockModal, setShowUnBlockModal] = useState(false);
  const [showblockModal, setShowBlockModal] = useState(false);
  const [showresignModal, setShowResignModal] = useState(false);

  useEffect(() => {
    TeacherList();
  }, [
    currentPage,
    teachername,
    teacheremail,
    contactnumber,
    showTeacherDelete,
  ]);

  const TeacherList = () => {
    let requestbody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      teacher_name: teachername,
      teacher_email: teacheremail,
      teacher_mobile: contactnumber,
      page: Number(currentPage),
      limit: 15,
    });
    simplePostCall(ApiConfig.TEACHER_LIST, requestbody)
      .then((data) => {
        setTeacherListapi(data?.list);
        console.log(data, "teacherapi");
        setNextPage(data?.next_page);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  const deleteTeacherId = (id) => {
    setShowTeacherId(id);
    console.log(id, "id");
  };
  // console.log(showTeacherId, "vannu");
  const deleteteacher = (id) => {
    let requestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_role: "teacher",
      teacher_id: id,
    });
    simplePostCall(ApiConfig.TEACHER_DELETE, requestBody)
      .then((data) => {
        setShowTeacherDelete(data);
        console.log(data, "deletesuccess");
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const teacherexcel = (exceldata) => {
    let requestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      format: exceldata,
    });
    simplePostCall(ApiConfig.TEACHER_EXPORT, requestBody)
      .then((data) => {
        if (data) {
          FileSaver.saveAs(data?.filepath);
          // console.log(data, "excelpdf");
        } else {
          console.log("Not Working");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  console.log(showBlockImage, "blockimage");
  const TeacherBlock = (id) => {
    let blockrequest = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_role: "teacher",
      teacher_id: id,
    });
    simplePostCall(ApiConfig.TEACHER_BLOCK, blockrequest)
      .then((data) => {
        setshowBlockResponse(data);
        console.log(data, "blockdata");
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const TeacherUnblock = (id) => {
    let unblockrequest = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_role: "teacher",
      teacher_id: id,
    });
    simplePostCall(ApiConfig.TEACHER_UNBLOCK, unblockrequest)
      .then((data) => {
        setshowUnBlockResponse(data);
        console.log(data, "unlockeddata");
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const TeacherResign = (rid) => {
    let resignrequestbody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_role: "teacher",
      teacher_id: rid,
    });
    simplePostCall(ApiConfig.TEACHER_RESIGN, resignrequestbody)
      .then((data) => {
        console.log(data, "resigndata");
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  console.log(showResign, "resignname");
  return (
    <>
      <div className="row edsys-table">
        <div className="col-sm-12">
          <div className="d-flex">
            <h5 className="mb-2 flex-grow-1">Teachers</h5>
            <div className="tablebuttonview">
              <a
                href="/TeacherRegisteration"
                type="button"
                className="btn timetablebutton "
              >
                <span>Registered Teacher</span>
              </a>
              <a
                href="/CreateTeacher"
                type="button"
                className="btn timetablebutton "
              >
                <span>Create Teacher</span>
              </a>
              <a
                href="/TeacherImport"
                type="button"
                className="btn timetablebutton"
              >
                <span>Import</span>
              </a>
              <a
                className="btn timetablebutton dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Export
              </a>

              <span class="visually-hidden">Toggle Dropdown</span>

              <ul class="dropdown-menu">
                <li
                  onClick={() => {
                    teacherexcel("pdf");
                  }}
                >
                  <a class="dropdown-item datasize" href="#">
                    Save as PDF
                  </a>
                </li>
                <li
                  onClick={() => {
                    teacherexcel("excel");
                  }}
                >
                  <a class="dropdown-item datasize" href="#">
                    Export to Excel
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="card">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Contact Number</th>
                    <th scope="col">Designation</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th></th>
                    <th>
                      <input
                        className="form-control"
                        type="Search"
                        placeholder="Search"
                        value={teachername}
                        onChange={(e) => setTeacherName(e.target.value)}
                      ></input>
                    </th>
                    <th>
                      <input
                        className="form-control"
                        type="Search"
                        placeholder="Search"
                        value={teacheremail}
                        onChange={(e) => setTeacherEmail(e.target.value)}
                      ></input>
                    </th>
                    <th>
                      <input
                        className="form-control"
                        type="Search"
                        placeholder="Search"
                        value={contactnumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                      ></input>
                    </th>
                    <th></th>
                    <th>
                      <div className="button_div">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={clearFields}
                        >
                          Clear
                        </button>
                      </div>
                    </th>
                  </tr>
                  {teacherListapi?.map((item, index) => {
                    // console.log(item.user_id, "teacheridvannuuuuu");

                    return (
                      <tr key={index}>
                        <th scope="row">{item.slnumber}</th>
                        <td>
                          <div className="subject_image_div">
                            <img
                              src={
                                item.user_profile_pic
                                  ? ApiConfig.NODE_URL +
                                    "/" +
                                    item.user_profile_pic
                                  : profilePic
                              }
                              className="subject_image"
                            />
                            {item.user_name}
                          </div>
                        </td>
                        <td>{item.user_email}</td>
                        <td>{item.user_mobile}</td>
                        <td>{item.teacher_designation}</td>
                        <td>
                          <div className="buttons">
                            <OverlayTrigger
                              key={item.number}
                              placement="top"
                              overlay={
                                <Tooltip id={"tooltip-${item.number}"}>
                                  View
                                </Tooltip>
                              }
                            >
                              <Link to="/TeacherDetailsView">
                                <div className="view">
                                  <HiMagnifyingGlassPlus className="image" />
                                </div>
                              </Link>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key={item.number}
                              placement="top"
                              overlay={
                                <Tooltip id={"tooltip-${item.number}"}>
                                  Edit
                                </Tooltip>
                              }
                            >
                              <Link
                                to={`/EditTeacher?message=${encodeURIComponent(
                                  item.user_id,
                                )}`}
                              >
                                <div className="view">
                                  <FaUserEdit className="image" />
                                </div>
                              </Link>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key={item.number}
                              placement="top"
                              overlay={
                                <Tooltip id={"tooltip-${item.number}"}>
                                  change password
                                </Tooltip>
                              }
                            >
                              <Link
                                to={`/ChangePassword?message=${encodeURIComponent(
                                  item.user_id,
                                )}`}
                              >
                                <div className="view">
                                  <FaUserLock className="image" />
                                </div>
                              </Link>
                            </OverlayTrigger>
                            {showBlockResponse && showBlockImage == index ? (
                              <OverlayTrigger
                                key={item.number}
                                placement="top"
                                overlay={
                                  <Tooltip id={"tooltip-${item.number}"}>
                                    Unblock
                                  </Tooltip>
                                }
                              >
                                <Link to="">
                                  <div
                                    className="view"
                                    onClick={() => {
                                      setShowUnBlockModal(true);
                                      setShowBlock(item.user_id);
                                      setshowBlockImage(index);
                                    }}
                                  >
                                    <FaUserAlt className="image" />
                                  </div>
                                </Link>
                              </OverlayTrigger>
                            ) : showUnBlockResponse &&
                              showBlockImage == index ? (
                              <OverlayTrigger
                                key={item.number}
                                placement="top"
                                overlay={
                                  <Tooltip id={"tooltip-${item.number}"}>
                                    Block
                                  </Tooltip>
                                }
                              >
                                <Link to="">
                                  <div
                                    className="view"
                                    onClick={() => {
                                      setShowBlockModal(true);
                                      setShowBlock(item.user_id);
                                      setshowBlockImage(index);
                                    }}
                                  >
                                    <FaUserAltSlash className="image" />
                                  </div>
                                </Link>
                              </OverlayTrigger>
                            ) : (
                              <OverlayTrigger
                                key={item.number}
                                placement="top"
                                overlay={
                                  <Tooltip id={"tooltip-${item.number}"}>
                                    Block
                                  </Tooltip>
                                }
                              >
                                <Link to="">
                                  <div
                                    className="view"
                                    onClick={() => {
                                      setShowBlockModal(true);
                                      setShowBlock(item.user_id);
                                      setshowBlockImage(index);
                                    }}
                                  >
                                    <FaUserAltSlash className="image" />
                                  </div>
                                </Link>
                              </OverlayTrigger>
                            )}
                            <OverlayTrigger
                              key={item.number}
                              placement="top"
                              overlay={
                                <Tooltip id={"tooltip-${item.number}"}>
                                  Resign
                                </Tooltip>
                              }
                            >
                              <Link to="">
                                <div
                                  className="view"
                                  onClick={() => {
                                    setShowResignModal(true);
                                    setShowResign(item.user_id);
                                  }}
                                >
                                  <FaUserXmark className="image" />
                                </div>
                              </Link>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key={item.number}
                              placement="top"
                              overlay={
                                <Tooltip id={"tooltip-${item.number}"}>
                                  Delete
                                </Tooltip>
                              }
                            >
                              <Link to="">
                                <div
                                  className="view"
                                  onClick={() => {
                                    setShowDeleteModal(true);
                                    deleteTeacherId(item.user_id);
                                    console.log(item.user_id, "brincy");
                                  }}
                                >
                                  <RiDeleteBin6Fill className="image" />
                                </div>
                              </Link>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="showingnumber">
                <div></div>
                <nav aria-label="Pagination">
                  <ul className="pagination">
                    <li
                      className={`page - item${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        style={{ backgroundColor: "#adc927" }}
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        aria-label="Previous"
                      >
                        &laquo;
                      </button>
                    </li>

                    <button
                      className="page-link"
                      style={{ backgroundColor: "#adc927" }}
                      onClick={() => handlePageChange()}
                    >
                      {currentPage}
                    </button>

                    <li
                      className={`page-item ${
                        currentPage === currentPage - 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        style={{ backgroundColor: "#adc927" }}
                        onClick={handleNextPage}
                        disabled={currentPage === currentPage - 1}
                        aria-label="next"
                      >
                        &raquo;
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Delete Modal Start */}

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-text-wrapper">
            Are you sure you want to delete ?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="delete-btn-wrapper border-top w-100">
            <button
              onClick={() => setShowDeleteModal(false)}
              className="dlt-btn-1"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setShowDeleteModal(false);

                deleteteacher(showTeacherId);
              }}
              type="submit"
              className="dlt-btn-2"
              value="delete"
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal End */}
      {/* Block Modal Start */}

      <Modal
        show={showblockModal}
        onHide={() => setShowBlockModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Block Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-text-wrapper">
            Do you really want to block ?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="delete-btn-wrapper border-top w-100">
            <button
              onClick={() => setShowBlockModal(false)}
              className="dlt-btn-1"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setShowBlockModal(false);
                TeacherBlock(showBlock);
              }}
              type="submit"
              className="dlt-btn-2"
              value="delete"
            >
              Ok
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Block Modal End */}

      {/* UnBlock Modal Start */}

      <Modal
        show={showunblockModal}
        onHide={() => setShowUnBlockModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            UnBlock Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-text-wrapper">
            Do you really want to unblock ?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="delete-btn-wrapper border-top w-100">
            <button
              onClick={() => setShowUnBlockModal(false)}
              className="dlt-btn-1"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setShowUnBlockModal(false);
                TeacherUnblock(showBlock);
              }}
              type="submit"
              className="dlt-btn-2"
              value="delete"
            >
              Ok
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* UnBlock Modal End */}

      {/* Resign Modal Start */}

      <Modal
        show={showresignModal}
        onHide={() => setShowResignModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Resign Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-text-wrapper">
            Are you sure you want to resign ?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="delete-btn-wrapper border-top w-100">
            <button
              onClick={() => setShowResignModal(false)}
              className="dlt-btn-1"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setShowResignModal(false);
                TeacherResign(showResign);
              }}
              type="submit"
              className="dlt-btn-2"
              value="delete"
            >
              Ok
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Resign Modal End */}
    </>
  );
}

export default Teachers;
