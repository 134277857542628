import React, { useState, useEffect } from "react";
import { ChatList, MessageBox, Input, Button } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import "../../../assets/css/ChatComponent.css";
import axios from "axios";

const Chat = () => {
  const [chatList, setChatList] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [page, setPage] = useState(1); // Current page number
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [hasMore, setHasMore] = useState(true); // Check if there's more data

  // Fetch API Data
  const fetchChatList = async (pageNo) => {
    if (isLoading || !hasMore) return; // Prevent duplicate calls

    setIsLoading(true);

    axios("https://app.edsys.in:3000/api/teacher/chat/list", {
      method: "GET",
      params: {
        teacher_id: 39165,
        page_no: pageNo,
        page_limit: 8,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
        api_key: "fd3d3375a2658b9177cfe0958446ea4f",
        customer_id: 1071,
      },
    })
      .then((response) => {
        if (response.data.result && response.data.list) {
          const formattedChatList = response.data.list.map((item) => ({
            id: item.chat_thread_id,
            title: item.receptient_name,
            subtitle:
              item.message_details.length > 0
                ? item.message_details[0].message_text || "No message"
                : "No message",
            date: new Date(
              item.message_details[0]?.message_date_time || Date.now(),
            ),
            unread: parseInt(item.unread_message_count, 10),
            avatar: item.receptient_user_profile_pic
              ? `https://your-image-host/${item.receptient_user_profile_pic}`
              : "https://via.placeholder.com/150",
          }));

          setChatList((prevChatList) => [
            ...prevChatList,
            ...formattedChatList,
          ]);

          // Check if more data is available
          if (response.data.list.length < 8) {
            setHasMore(false); // No more pages to fetch
          }
        }
      })
      .catch((err) => {
        console.log("Error fetching chat list: ", err);
      })
      .finally(() => setIsLoading(false));
  };
  // Fetch Chat History Data based on the active chat
  const fetchChatHistoryApi = (threadId) => {
    axios("https://app.edsys.in:3000/api/teacher/chat/message/list", {
      method: "GET",
      params: {
        thread_id: threadId,
        page_no: 1,
        page_limit: 10,
        teacher_id: 39165,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
        api_key: "fd3d3375a2658b9177cfe0958446ea4f",
        customer_id: 1071,
      },
    })
      .then((response) => {
        if (response.data.result && response.data.data) {
          const formattedMessages = response.data.data.map((msg) => ({
            position: msg.message_from_id === 39165 ? "right" : "left",
            type: "text",
            text: msg.message_text,
            date: new Date(msg.message_date_time),
          }));
          setMessages(formattedMessages);
        }
      })
      .catch((err) => {
        console.log("Error fetching chat history: ", err);
      });
  };
  // Load the first page initially
  useEffect(() => {
    fetchChatList(page);
  }, [page]);
  // Load messages when an active chat is selected
  useEffect(() => {
    if (activeChat) {
      fetchChatHistoryApi(activeChat.id);
    }
  }, [activeChat]);

  // Load messages for the active chat
  useEffect(() => {
    if (activeChat) {
      const newMessages = [
        {
          position: "left",
          type: "text",
          text: `Chat with ${activeChat.title} started.`,
          date: new Date(),
        },
      ];
      setMessages(newMessages);
    }
  }, [activeChat]);

  // Handle sending message
  const handleSendMessage = () => {
    if (inputText.trim() !== "") {
      const newMessage = {
        position: "right",
        type: "text",
        text: inputText,
        date: new Date(),
      };

      // Append the new message to the end of the messages list
      setMessages((prevMessages) => [newMessage, ...prevMessages]);

      // Clear the input field
      setInputText("");
    }
  };

  return (
    <div className="chat-container">
      {/* Sidebar */}
      <div className="chat-sidebar">
        <h2>Chats</h2>
        <ChatList
          className="chat-list"
          dataSource={chatList}
          onClick={(chat) => setActiveChat(chat)}
        />

        {/* Load More Button */}
        {hasMore && (
          <div className="load-more-container">
            <button
              onClick={() => setPage((prevPage) => prevPage + 1)}
              disabled={isLoading}
              className="load-more-button"
            >
              {isLoading ? "Loading..." : "Load More"}
            </button>
          </div>
        )}
      </div>

      {/* Chat Window */}
      <div className="chat-window">
        {activeChat ? (
          <>
            {/* Header */}
            <div className="chat-header">
              <h3>{activeChat.title}</h3>
            </div>

            {/* Messages */}
            <div className="chat-messages">
              {messages
                .slice(0)
                .reverse()
                .map((msg, index) => (
                  <MessageBox
                    key={index}
                    position={msg.position}
                    type={msg.type}
                    text={msg.text}
                    date={msg.date}
                  />
                ))}
            </div>

            {/* Input Field */}
            <div className="chat-input">
              <Input
                placeholder="Type a message..."
                multiline={false}
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                rightButtons={
                  <Button
                    color="white"
                    backgroundColor="blue"
                    text="Send"
                    onClick={handleSendMessage}
                  />
                }
              />
            </div>
          </>
        ) : (
          <div className="chat-placeholder">
            <h3>Select a chat to start messaging</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;
