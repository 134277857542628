//import AppStrings from "../constants/AppStrings";

import AppStrings from "../api/AppStrings";

// const customer_id = 1602;
// const api_key = "3fc5191145aa71df4557f70f4603b45d";
// const teacher_id = 47147;
// const is_logged_in = true;

const customer_id = JSON.parse(localStorage.getItem("CUSTOMER_ID"));
const api_key = JSON.parse(localStorage.getItem("API_KEY"));
const teacher_id = JSON.parse(localStorage.getItem("TEACHER_ID"));
const is_logged_in = JSON.parse(localStorage.getItem("is_logged_in"));
const user_id = JSON.parse(localStorage.getItem(AppStrings.USER_ID));
const Params = {
  customer_id: customer_id,
  api_key: api_key,
  teacher_id: teacher_id,
  is_logged_in: is_logged_in,
  user_id: user_id,
};

export default Params;
