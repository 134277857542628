import React, { useState } from "react";
import { IoClose } from "react-icons/io5";

function TeacherImport() {
  const [showclose, setShowClose] = useState();
  const [showimage, setShowImage] = useState();
  return (
    <div className="row edsys-table">
      <div className="col-sm-12">
        <div className="d-flex">
          <h5 className="mb-2 flex-grow-1">Import Teacher Data</h5>
        </div>
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-lg-6">
                {showclose ? (
                  <div className="div_Succesfull">
                    <label>Imported Successfully</label>
                    <IoClose onClick={() => setShowClose(!showclose)} />
                  </div>
                ) : null}

                <div className="form-group col-md-12">
                  <label for="task-title">Upload File</label>
                  <input
                    className="datepicker-here form-control digits"
                    type="file"
                  />
                </div>
                <div className="teacherimportbutton">
                  <div className="">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => setShowClose(true)}
                    >
                      Import
                    </button>
                  </div>
                  <div className="">
                    <button className="btn btn-primary" type="button">
                      Download Import Format
                    </button>
                  </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <label className="Instruction_label">
                    Please follow the instruction before uploading the file.
                  </label>
                  <ul className="Instruction_list">
                    <li>Uploading file should be a .xls file.</li>
                    <li>
                      Date format is Year-Month-Date for example 2014-12-20.
                    </li>
                    <li>
                      Successive rows should be filled out from first row
                      onwords.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                {showimage ? (
                  <div className="div_Succesfull">
                    <label>Imported Successfully</label>
                    <IoClose onClick={() => setShowImage(!showimage)} />
                  </div>
                ) : null}

                <div className="form-group col-md-12">
                  <label for="task-title">Upload File</label>
                  <input
                    className="datepicker-here form-control digits"
                    type="file"
                  />
                </div>
                <div className="schedulebutton">
                  <div className="">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => setShowImage(true)}
                    >
                      Upload Images
                    </button>
                  </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <label className="Instruction_label">
                    This feature is used to upload images of imported Teachers
                    using excel sheet.
                  </label>
                  <ul className="Instruction_list">
                    <li>Image name shoud be specified in excel.</li>
                    <li>
                      Uploading file should be a .zip file of single image or
                      multiple images.
                    </li>
                    <li>
                      zip file should not include any file other than image
                      extension.
                    </li>
                    <li>
                      Create a new folder and copy images to that folder then
                      compress that folder to zip format.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeacherImport;
