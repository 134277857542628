import React, { useState } from "react";

import question from "../../../assets/images/masterdatapic/question.png";
import tick from "../../../assets/images/masterdatapic/tick.png";
import cross from "../../../assets/images/masterdatapic/cross.png";
const SubjectLecture = (props) => {
  // debugger
  const data = props.data;
  const images = [tick, cross, question];
  const words = ["Available", "Not Available", "Not Scheduled"];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClick = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  return (
    <div class={"tt-col tt-content " + props.weekDayName}>
      <div class="tt-desc tt-desc-inner">
        <div className="App">
          <img
            src={images[currentIndex]}
            alt="Slideshow"
            onClick={handleClick}
            style={{ width: "48px", height: "48px", cursor: "pointer" }}
          />
        </div>
        <div class="title">{data.name}</div>
        <div class="duration">
          <div
            className="status"
            style={{
              cursor: "pointer",
              fontSize: "12px",
              marginBottom: "10px",
            }}
          >
            {words[currentIndex]}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubjectLecture;
