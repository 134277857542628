import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import "../assets/css/bootstrap.css";
import { FaUserGraduate } from "react-icons/fa";

// import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from "react-i18next";

import {
  ArrowLeft,
  Settings,
  Home,
  Airplay,
  Box,
  ArrowRight,
  AlignRight,
} from "react-feather";
import dashboardAvatar from "../assets/images/dashboard/1.png";
import dashboard_icon from "../assets/images/sidebar-menu/dashboard.svg";
import attendance_icon from "../assets/images/sidebar-menu/attendance.svg";
import announcement_icon from "../assets/images/sidebar-menu/announcement.svg";
import academic_icon from "../assets/images/sidebar-menu/academic.svg";
import e_learning_icon from "../assets/images/sidebar-menu/e-learning.svg";
import leave_icon from "../assets/images/sidebar-menu/leave.svg";
import reports_icon from "../assets/images/sidebar-menu/reports.svg";
import student_icon from "../assets/images/sidebar-menu/student.svg";
import timetable_icon from "../assets/images/sidebar-menu/timetable.svg";
import to_do_icon from "../assets/images/sidebar-menu/to-do.svg";
import communication_icon from "../assets/images/sidebar-menu/Communication.svg";
import masterdata_icon from "../assets/images/sidebar-menu/masterdata.svg";

function Sidebar() {
  const { t } = useTranslation();
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [open, setOpen] = useState(false);

  // const Wrapper = styled.aside`
  // background: #ccc;
  // width: 300px;
  // `;

  // const menus = [
  // {
  //   label: "Menu 1"
  // },
  // {
  //   label: "Menu 2",
  //   submenu: [
  //     {
  //       label: "Sub Menu 1"
  //     },
  //     {
  //       label: "Sub Menu 2"
  //     }
  //   ]
  // },
  // {
  //   label: "Menu 3",
  //   submenu: [
  //     {
  //       label: "Sub Menu 1",
  //       submenu: [
  //         {
  //           label: "Boom 1"
  //         },
  //         {
  //           label: "Boom 2"
  //         }
  //       ]
  //     },
  //     {
  //       label: "Sub Menu 2",
  //       submenu: [
  //         {
  //           label: "Deep 1"
  //         },
  //         {
  //           label: "Deep 2",
  //           submenu: [
  //             {
  //               label: "Lorem 1"
  //             },
  //             {
  //               label: "Lorem 2",
  //               submenu: [
  //                 {
  //                   label: "Super Deep"
  //                 }
  //               ]
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       label: "Sub Menu 3"
  //     },
  //     {
  //       label: "Sub Menu 4",
  //       submenu: [
  //         {
  //           label: "Last 1"
  //         },
  //         {
  //           label: "Last 2"
  //         },
  //         {
  //           label: "Last 3"
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   label: "RSS Menu 4"
  // }
  // ];
  const handleTab = () => {
    if (click == true) {
      document.getElementById("page_container").style.marginLeft = "290px";
      document.getElementById("the_footer").style.marginLeft = "290px";
      setClick(false);
    } else {
      document.getElementById("page_container").style.marginLeft = "0px";
      document.getElementById("the_footer").style.marginLeft = "0px";
      setClick(true);
    }
  };
  const scriptAlreadyExists = () =>
    document.querySelector("script#fb-sdk") !== null;
  const appendSdkScript = () => {
    const script = document.createElement("script");
    script.id = "fb-sdk";
    script.src = "../assets/js/sidebar-menu.js";
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    document.body.append(script);
  };
  useEffect(async () => {
    // debugger
    if (!scriptAlreadyExists()) {
      appendSdkScript();
    }
  }, []);

  return (
    //Page Sidebar Start
    <nav>
      {/* <Wrapper>
<MultiMenus menus={menus} />
</Wrapper> */}
      <div className="main-navbar">
        <div className="left-arrow" id="left-arrow">
          <ArrowLeft />
        </div>
        <div id="mainnav">
          <ul className="nav-menu custom-scrollbar">
            <li className="back-btn">
              <div className="mobile-back text-end">
                <span>Back</span>
                <i className="fa fa-angle-right ps-2" aria-hidden="true"></i>
              </div>
            </li>
            <li className="dropdown mb-2" type="button">
              <NavLink to="/" className="nav-link menu-title link-nav ">
                <img src={dashboard_icon} alt="" />
                <span>{t("Dashboard")}</span>
              </NavLink>
            </li>
            <li className="dropdown" type="button">
              <a href="#" className="nav-link menu-title">
                {/* <NavLink to="/my-attendance" className="nav-link menu-title"> */}
                <img src={attendance_icon} alt="" /> <span> Attendance</span>
                {/* </NavLink> */}
              </a>

              <ul className="nav-submenu menu-content">
                <li>
                  <Link to="/my-attendance">{t("My Attendance")}</Link>
                </li>
                <li>
                  <Link to="/studentAttendance">{t("Student Attendance")}</Link>
                </li>
              </ul>
            </li>
            {/* Feeds */}
            <li className="dropdown mb-2" type="button">
              <NavLink to="/Forum" className="nav-link menu-title link-nav">
                <img src={e_learning_icon} alt="" />
                <span>{t("Forum")}</span>
              </NavLink>
            </li>
            {/* My Diary section */}
            <li className="dropdown" type="button">
              <a href="#" className="nav-link menu-title">
                {/* <NavLink to="/my-attendance" className="nav-link menu-title"> */}
                <img src={masterdata_icon} alt="" />{" "}
                <span>{t("Master Data")}</span>
                {/* </NavLink> */}
              </a>

              <ul className="nav-submenu menu-content">
                <li>
                  <Link to="/Subjects">{t("Subjects")}</Link>
                </li>
                <li>
                  <Link to="/Teachers">{t("Teachers")}</Link>
                </li>
              </ul>
            </li>

            {/* My Notice section */}

            {/* <li className="dropdown mb-2" type="button">
              <NavLink
                to="/NoticeBoard"
                className="nav-link menu-title link-nav"
              >
                <img src={e_learning_icon} alt="" />
                <span>{t("Notice")}</span>
              </NavLink>
            </li> */}

            {/* Attendance Not needed */}

            {/* <li className="dropdown mb-2" type="button">
              <NavLink
                to="/my-attendance"
                className="nav-link menu-title link-nav"
              >
                <img src={attendance_icon} alt="" />{" "}
                <span>{t("My Attendance")}</span>
              </NavLink>
            </li> */}

            {/* Not Needed section */}

            {/* New Screens (18/05/2023) Start */}
            {/* <li className="dropdown mb-2" type="button">
              <a href="#" className="nav-link menu-title">
                <img src={communication_icon} />
                <span>{t("Communication New")}</span>
              </a>
              <ul className="nav-submenu menu-content">
                <li>
                  <Link to="/AnnouncementNew">{t("SMS New")}</Link>
                </li>
                <li>
                  <Link to="/EmailNew">{t("Email New")}</Link>
                </li>
                <li>
                  <Link to="/PushNotificationNew">{t("Push Notification New")}</Link>
                </li>
                <li>
                  <Link to="/Chat">{t("Chat")}</Link>
                </li>
              </ul>
            </li> */}
            {/* New Screens (18/05/2023) End */}
            <li className="dropdown mb-2" type="button">
              <a href="#" className="nav-link menu-title">
                <img src={academic_icon} />
                <span>{t("Academics")}</span>
              </a>
              <ul className="nav-submenu menu-content">
                <li>
                  <Link to="/Assignment">{t("Assignments")}</Link>
                </li>
                <li>
                  <Link to="/Assessment">{t("Assessments")}</Link>
                </li>
                <li>
                  <Link to="/E_Learning">{t("E-Learning")}</Link>
                </li>
                <li>
                  <Link to="/OfflineAssesmentHome">
                    {t("Offline Assesment")}
                  </Link>
                </li>
                <li>
                  <Link to="/LessonTopicAssessmentHome">
                    {t("Lesson/Topic wise Assessment")}
                  </Link>
                </li>

                {/* <li>
                  <Link to="/ChannelList">{t("Channel List")}</Link>
                </li> */}
                {/* <li>
                  <Link to="/calendar">{t("Calender")}</Link>
                </li> */}
                {/* syllabus needed to implement */}
                <li>
                  {/* <Link to="/Syllabus">Syllabus</Link> */}
                  <Link to="/NewSyllabus">{t("Syllabus")}</Link>
                </li>
                <li>
                  <Link to="/syllabus-prog">{t("SyllabusProgress")}</Link>
                </li>
                {/* <li>
                  <Link to="/video-conference">{t("Video Conference")}</Link>
                </li> */}
                {/* <li>
                  <Link to="/VideoConferenceNew">
                    {t("Video Conference New")}
                  </Link>
                </li> */}
              </ul>
            </li>
            <li className="dropdown" type="button">
              <a href="#" className="nav-link menu-title">
                {/* <NavLink to="/my-attendance" className="nav-link menu-title"> */}
                <FaUserGraduate className="studentedit" />
                <span> {t("Student Management")}</span>
                {/* </NavLink> */}
              </a>

              <ul className="nav-submenu menu-content">
                <li>
                  <Link to="/Studentlistnew">{t("Student List")}</Link>
                </li>
                <li>
                  <Link to="/Promotion">{t("Promotion")}</Link>
                </li>
              </ul>
            </li>
            <li className="dropdown mb-2" type="button">
              <a href="#" className="nav-link menu-title">
                <img src={leave_icon} alt="" />
                <span>{t("Leaves")}</span>
              </a>
              <ul className="nav-submenu menu-content">
                <li>
                  <Link to="/EmpLeaves">{t("My Leaves")}</Link>
                </li>
                <li>
                  <Link to="/StudentLeaves">{t("Student Leaves")}</Link>
                </li>
                <li>
                  <Link to="/ApproveLeaves">{t("Subordinate Leaves")}</Link>
                </li>
              </ul>
            </li>
            {/* Communication section */}
            <li className="dropdown mb-2" type="button">
              <a href="#" className="nav-link menu-title">
                <img src={announcement_icon} />
                <span>{t("Communication")}</span>
              </a>
              <ul className="nav-submenu menu-content">
                <li>
                  <Link to="/Announcement">{t("Announcement")}</Link>
                </li>
                {/* <li>
                  <Link to="/Chat">{t("Chat")}</Link>
                </li> */}
                {/* <li>
                  <Link to="/Email">{t("Email")}</Link>
                </li>
                <li>
                  <Link to="/sms">{t("SMS")}</Link>
                </li>
                <li>
                  <Link to="/push-notification">{t("Push Notification")}</Link>
                </li> */}
              </ul>
            </li>
            {/* students list */}

            {/* <li className="dropdown mb-2" type="button">
              <a href="#" className="nav-link menu-title">
                <img src={student_icon} alt="" />
                <span>{t("Students")}</span>
              </a>
              <ul className="nav-submenu menu-content">
                <li>
                  <Link to="/StudentList">{t("Student List")}</Link>
                </li>
              </ul>
            </li> */}

            {/* E-learning separate no need */}

            {/* <li className="dropdown" type="button">
              <Link
                to="/E_Learning"
                className="nav-link menu-title link-nav"
                href="e-learning.html"
              >
                <img src={e_learning_icon} alt="" />
                <span>E-Learning</span>
              </Link>
            </li> */}

            {/* Library new section */}

            <li className="dropdown mb-2" type="button">
              <NavLink to="/Library" className="nav-link menu-title link-nav">
                <img src={e_learning_icon} alt="" /> <span>{t("Library")}</span>
              </NavLink>
            </li>

            {/* School Channel */}

            {/* <li className="dropdown mb-2" type="button">
              <NavLink
                to="/SchoolTvChannel"
                className="nav-link menu-title link-nav"
              >
                <img src={e_learning_icon} alt="" />{" "}
                <span>{t("School Tv Channel")}</span>
              </NavLink>
            </li> */}

            {/* Forum section */}

            {/* <li className="dropdown mb-2" type="button">
              <NavLink to="/Forum" className="nav-link menu-title link-nav">
                <img src={e_learning_icon} alt="" />
                <span>{t("Forum")}</span>
              </NavLink>
            </li> */}
            <li className="dropdown mb-2" type="button">
              <NavLink to="/timetable" className="nav-link menu-title link-nav">
                <img src={timetable_icon} alt="" />

                <span
                  className={"toggle-sidebar"}
                  // onClick={() => [setClick(false), handleTab()]}
                >
                  {t("Timetable")}
                </span>
              </NavLink>
            </li>

            <li className="dropdown mb-2" type="button">
              <NavLink
                to="/AcademicCalendar"
                className="nav-link menu-title link-nav"
              >
                <img src={timetable_icon} alt="" />
                <span>Academic Calender</span>
              </NavLink>
            </li>

            {/* My Diary section */}

            <li className="dropdown mb-2" type="button">
              <NavLink to="/MyDiary" className="nav-link menu-title link-nav">
                <img src={e_learning_icon} alt="" />
                <span>{t("My Diary")}</span>
              </NavLink>
            </li>
            {/* Reports section */}

            {/* <li className="dropdown mb-2" type="button">
              <Link to="/Assessment" className="nav-link menu-title link-nav">
                <img src={reports_icon} alt="" />
                <span>Reports</span>
              </Link>
            </li> */}

            {/* To do list  */}

            <li className="dropdown mb-2" type="button">
              <Link to="/todo" className="nav-link menu-title link-nav">
                <img src={to_do_icon} alt="" />
                <span>{t("To-Do")}</span>
              </Link>
            </li>

            <li className="dropdown" type="button">
              {/* <a href="#" className="nav-link menu-title"> */}
              <NavLink to="/Feed" className="nav-link menu-title">
                <img src={masterdata_icon} alt="" /> <span>{t("Feed")}</span>
              </NavLink>
              {/* </a> */}
            </li>
          </ul>
        </div>
        <div className="right-arrow" id="right-arrow">
          {" "}
          <ArrowRight />{" "}
        </div>
      </div>
    </nav>
    //Page Sidebar Ends
  );
}

export default Sidebar;
