import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import dropdown_icons from "../../assets/images/dropdown_icons.svg";
import forum_img_1 from "../../assets/images/forum_img_1.svg";
import filter_icon from "../../assets/images/filter_icon.svg";
import share_icon from "../../assets/images/share_icon.svg";
import Delete from "../../assets/images/delete_icon.svg";
import forum_img_com from "../../assets/images/forum_img_com.svg";
import like_btn from "../../assets/images/like_btn.svg";
import Likee from "../../assets/images/Likee.svg";
import dislike_btn from "../../assets/images/dislike_btn.svg";
import com_icon from "../../assets/images/com_icon.svg";
import edit_icon from "../../assets/images/edit_icon.svg";
import dislike_icon from "../../assets/images/dislikee.svg";
import Modal from "react-bootstrap/Modal";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import add_btn from "../../assets/images/add_btn.svg";
import Form from "react-bootstrap/Form";
import ListSkeleton from "../../components/skeletons/ListSkeleton";
import ApiConfig from "../../api/ApiConfig";
import axios from "axios";
import Params from "../../config/Params";
import { toast } from "react-toastify";
import { IoIosClose } from "react-icons/io";
import { io } from "socket.io-client";
import { BiDislike, BiLike, BiSolidDislike, BiSolidLike } from "react-icons/bi";

function Forum() {
  const navigation = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [validated, setValidated] = useState(false);

  //////////////////////////////////////////Start------UseState\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  const [liked, setLiked] = useState(true);
  const [categoryLists, setCategoryLists] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isloading, setIsloading] = useState(false);
  const [data, setData] = useState([]);
  const [more, setMore] = useState("");
  const [toggleAllForum, setToggleAllForum] = useState(true);
  const [toggleManageForum, setToggleManageForum] = useState(true);
  const [page, setPage] = useState(1);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [totalPages, setTotalPages] = useState(1); // Total pages available
  const [section, setSection] = useState("");
  const [isShownItem, setIsShownItem] = useState({});
  const [createMeassage, setCreateMeassage] = useState("");
  const [forumId, setForumId] = useState("");
  const [socket, setSocket] = useState("");

  const [filterData, setFilterData] = useState([
    { id: 1, label: "Most Recent", value: false },
    { id: 2, label: "Most Liked", value: false },
    { id: 3, label: "Most Commented", value: false },
  ]);

  //////////////////////////////////////////Ends----Usestate\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

  const handleSubmitone = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      CreateForumApi();
    }

    setValidated(true);
  };
  //////////////////////////////////////////My\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

  const handleRadioChange = (id) => {
    setSelectedOption(id);
  };
  const handleClear = () => {
    setSelectedOption("");
  };
  //------------------------Start----create_Forum_Api---List--------------------------//
  const HandleForumone = (item = null, item2 = null) => {
    try {
      axios(ApiConfig.ALL_FORUM, {
        method: "GET",
        params: {
          like_dislike: item2 === 2 ? true : null,
          most_commented: item2 === 3 ? true : null,
          user_id: Params.teacher_id,
          page_limit: 3,
          page_no: Number(currentPage),
          teacher_id: item ? Params.teacher_id : null,
        },

        headers: {
          Accept: "application/json",
          api_key: Params.api_key,
          customer_id: Params.customer_id,
        },
      }).then((data) => {
        if (data.data.result) {
          setData(data.data.data);
          setPage(data.data.next_page);

          setIsloading(false);
        } else {
          setIsloading(false);
          setData([]);
        }
      });
    } catch (error) {
      console.log("home error:", error);
      setIsloading(false);
      setData([]);
    }
  };
  //------------------------Ends----create_Forum_Api---List--------------------------//
  //------------------------Start----create_Forum_Api---Date--------------------------//
  function formatDate(dateString) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const dateObj = new Date(dateString);
    const day = dateObj.getDate();
    const month = months[dateObj.getMonth()];
    const year = dateObj.getFullYear();

    return `${month} ${day}, ${year}`;
  }
  //------------------------Ends----create_Forum_Api---List--------------------------//
  useEffect(() => {
    HandleForumone();
    handlePageChange();
  }, []);
  const handleclick = (id, value) => {
    const updateFilterData = filterData.map((item) => {
      if (item.id === id) {
        return { ...item, value: true };
      }
      return { ...item, value: false };
    });
    setFilterData(updateFilterData);
    setShow(false);
    HandleForumone(
      value === true ? true : value === false ? false : toggleAllForum,
      id,
    );
  };
  const HandleAllForumToggle = () => {
    setMore("");
    setToggleAllForum(true);
    setToggleManageForum(false);
    handleclick(1, true);
  };
  const HandleManageForumToggle = () => {
    setMore("");
    setToggleAllForum(false);
    setToggleManageForum(true);
    handleclick(1, false);
  };

  //------------------------Start---create_Forum_Api--------------------------//

  const customer_id = Params.customer_id;
  const user_id = Params.teacher_id;
  console.log(customer_id, user_id, "customer_id12312312");
  //------------------------Start----create_Forum_Api---Like--------------------------//
  const LikeCountEvent = (ids) => {
    console.log(ids, "forumid");
    const event = {
      like_dislike: true,
      user_role: "teacher",
      forum_id: ids,
    };
    socket.emit("forum_like", event, () => {
      console.log("likeEvent is emited");
    });
  };
  const DisLikeCount = (ids) => {
    console.log(ids, "forumid");
    const event = {
      like_dislike: false,
      user_role: "teacher",
      forum_id: ids,
    };
    socket.emit("forum_like", event, () => {
      console.log("dislikedevent is emitted");
    });
  };
  useEffect(() => {
    const customerId = Params.customer_id;
    const userId = Params.user_id;
    const socket = io(
      ApiConfig.NODE_URL + "/?customerId=" + customerId + "&userId=" + userId,
    );
    setSocket(socket);
    socket.emit("forum", () => {
      console.log("forum room is create");
    });
    socket.on("forum_like_count", (database) => {
      console.log(database, "socket response");
      HandleSocket(database);
    });
    socket.on("newforum", (database) => {
      console.log("newFeed received", database);
    });
    socket.on("disconnect", (reason) => {
      console.log("Socket disconnected:", reason);
    });
    return () => {
      if (socket) {
        socket.disconnect();
        console.log("Socket manually disconnected");
      }
    };
  }, []);

  const HandleSocket = (conn) => {
    console.log(conn, "response from socket data");
    setData((prevFeedLists) => {
      const UpdateListedForum = prevFeedLists?.map((forum) => {
        if (forum.forum_id == conn.forum_id) {
          return {
            ...forum,
            forum_like_count: data.forum_like_count,
            forum_dislike_count: data.forum_dislike_count,
            existing_like: data.existing_like,
            existing_dislike: data.existing_dislike,
          };
        }
        return forum;
      });
      const forumExisting = UpdateListedForum.some(
        (forum) => forum.forum_id == conn.forum_id,
      );
      if (!forumExisting) {
        return [
          ...UpdateListedForum,
          {
            forum_id: data.forum_id,
            forum_like_count: data.forum_like_count,
            forum_dislike_count: data.forum_dislike_count,
            existing_like: data.existing_like,
            existing_dislike: data.existing_dislike,
          },
        ];
      }
      return UpdateListedForum;
    });
  };

  //------------------------Ends----create_Forum_Api---Like--------------------------//

  const handleCatorylist = (catid) => {
    setCategory(catid);
    console.log(catid, "This will be the category id");
  };
  const handleFileUpdate = (e) => {
    setFile((prevAttachment) => [
      ...prevAttachment,
      ...Array.from(e.target.files),
    ]);
  };
  const handleDelete = (index) => {
    const newFiles = [...file];
    newFiles.splice(index, 1);
    setFile(newFiles);
  };
  const Handleclearcreate = () => {
    setTitle("");
    setDescription("");
    setCategory("");
  };
  //------------------------End----create_Forum_Api--------------------------//
  const CreateForumApi = async () => {
    if (section == "create") {
      console.log("create API is working");
      const url = ApiConfig.FORUM_CREATE;
      const headers = {
        Accept: "application/json",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
        customer_id: Params.customer_id,
        api_key: Params.api_key,
      };
      const formData = new FormData();
      formData.append("forum_title", title);
      formData.append("forum_createdby", Params.user_id);
      // formData.append("api_key", Params.api_key);
      formData.append("forum_description", description);
      formData.append("forum_category", category);
      file.forEach((files) => {
        formData.append("forum_attachment", files);
      });
      try {
        const response = await axios.post(url, formData, { headers });
        console.log(response, "forum creayed successfully");
        setCreateMeassage(response);
        toast.success(response.data.message);
      } catch (error) {
        console.log(error, "axios error");
        toast.error("error in creating forum");
      }
    } else if (section == "edit") {
      const url = ApiConfig.FORUM_EDIT;
      const headers = {
        Accept: "application/json",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
        customer_id: Params.customer_id,
        api_key: Params.api_key,
      };
      const formData = new FormData();
      formData.append("forum_title", title);
      formData.append("forum_createdby", Params.user_id);
      // formData.append("api_key", Params.api_key);
      formData.append("forum_description", description);
      formData.append("forum_category", category);
      file.forEach((files) => {
        formData.append(
          "forum_attachment",
          files || isShownItem.forum_attachment,
        );
      });
      formData.append("forum_id", forumId);
      try {
        const response = await axios.post(url, formData, { headers });
        setCreateMeassage(response);
        toast.success(response.data.message);
      } catch (error) {
        console.log(error, "axios errer");
        toast.error("This is the Error");
      }
    }
  };

  //------------------------Start----create_Forum_Api---Pagination--------------------------//
  const handlePageChange = () => {
    if (page != false) {
      fetchForumData(page);
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (page !== false) {
      setCurrentPage(currentPage + 1);
    }
  };

  const itemsPerPage = 10;
  const fetchForumData = async (page = 1, filter = null) => {
    setIsloading(true);
    try {
      const response = await axios(ApiConfig.ALL_FORUM, {
        method: "GET",
        params: {
          like_dislike: filter === 2 ? true : null,
          most_commented: filter === 3 ? true : null,
          user_id: Params.teacher_id,
          page_limit: itemsPerPage,
          page_no: page,
        },
        headers: {
          Accept: "application/json",
          api_key: Params.api_key,
          customer_id: Params.customer_id,
        },
      });

      if (response.data.result) {
        setData(response.data.data); // Set current page data
        setCurrentPage(page); // Update current page
        setTotalPages(response.data.total_pages || 1); // Update total pages
      } else {
        setData([]); // Clear data if no results
      }
    } catch (error) {
      console.error("Error fetching forum data:", error);
      setData([]);
    } finally {
      setIsloading(false);
    }
  };

  const renderPagination = () => (
    <div className="showingnumber">
      <div></div>
      <nav aria-label="Pagination">
        <ul className="pagination">
          <li className={`page - item${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              style={{ backgroundColor: "#adc927" }}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              aria-label="Previous"
            >
              &laquo;
            </button>
          </li>

          <button
            className="page-link"
            style={{ backgroundColor: "#adc927" }}
            onClick={() => handlePageChange()}
          >
            {currentPage}
          </button>

          <li
            className={`page-item ${
              currentPage === currentPage - 1 ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              style={{ backgroundColor: "#adc927" }}
              onClick={handleNextPage}
              disabled={currentPage === currentPage - 1}
              aria-label="next"
            >
              &raquo;
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );

  const SelectCategoryList = async () => {
    const url = ApiConfig.FORUM_CATEGORY;
    const headers = {
      customer_id: Params.customer_id,
      api_key: Params.api_key,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data.result == true) {
        setCategoryLists(response?.data?.data);
      }
    } catch (error) {
      console.log(error, "This is the axios error");
    }
  };

  useEffect(() => {
    SelectCategoryList();
  }, []);

  return (
    <>
      <div class="Forum library">
        {isloading ? (
          <ListSkeleton />
        ) : (
          <div className="main-section">
            <div className="row">
              <div className="col-md-12">
                <div className="announcement-top">
                  <label>Forums</label>
                  <div className="top-btn-wrapper">
                    <button onClick={() => setModalShow(true)}>
                      <img src={add_btn} alt="" />
                      Create Forum
                    </button>
                    <img src={filter_icon} alt="" onClick={handleShow} />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="books-main-wrapper">
                  <TabView>
                    <TabPanel
                      header="All Forums"
                      onClick={HandleAllForumToggle}
                    >
                      {data.map((item, index) => {
                        return (
                          <div className="forum-item" key={index}>
                            <div className="forum-header">
                              <div className="forum-img">
                                <img src={forum_img_1} alt="" />
                              </div>
                              <div className="forum-title-box">
                                <div className="forum-title-wrapper">
                                  <p className="ft-main-title">
                                    {item.forum_title}
                                  </p>

                                  <div className="d-flex flex-row">
                                    <img
                                      src={{
                                        uri:
                                          ApiConfig.PROFILE_PIC +
                                          item.user_profile_pic,
                                      }}
                                    />
                                    <p className="ft-sub-title">
                                      {item.forum_created_by}
                                    </p>
                                    {"/"}
                                    <p className="ft-sub-title">
                                      {item.user_role}
                                    </p>
                                  </div>
                                  <p className="ft-sub-title">
                                    {item.category_name}
                                  </p>
                                  <p className="ft-date">
                                    {formatDate(item.forum_created_date)}
                                  </p>
                                </div>
                                <div className="forum-dropdown">
                                  <Dropdown className="Common_dropDown">
                                    <Dropdown.Toggle
                                      className="dropdown-btn"
                                      id="dropdown-basic"
                                    >
                                      <img src={dropdown_icons} alt="" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item>
                                        <div className="d-flex flex-row">
                                          <img src={share_icon} alt="" />
                                          <p>Share</p>
                                        </div>
                                      </Dropdown.Item>
                                      <Dropdown.Item>
                                        <div
                                          className="d-flex flex-row"
                                          onClick={() => {
                                            setModalShow1(true);
                                            setForumId(item.forum_id);
                                            setIsShownItem(item);
                                          }}
                                        >
                                          <img src={edit_icon} alt="" />
                                          <p>Edit</p>
                                        </div>
                                      </Dropdown.Item>
                                      <Dropdown.Item>
                                        <div className="d-flex flex-row">
                                          <img src={Delete} alt="" />
                                          <p>Delete</p>
                                        </div>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>

                            <div className="forum-footer">
                              <div className="d-flex flex-row ">
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => LikeCountEvent(item.forum_id)}
                                >
                                  {item.existing_like ? (
                                    <BiSolidLike
                                      style={{
                                        width: "18px",
                                        height: "18px",
                                        color: "#954386",
                                      }}
                                    />
                                  ) : (
                                    <BiLike
                                      style={{
                                        width: "18px",
                                        height: "18px",
                                        color: "#954386",
                                      }}
                                    />
                                  )}
                                  {/* <img src={like_btn} alt="like button" /> */}
                                </div>
                                <span className="ms-0.5">
                                  {item.forum_like_count}
                                </span>
                              </div>
                              <div className="d-flex flex-row ms-4">
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => DisLikeCount(item.forum_id)}
                                >
                                  {item.existing_dislike ? (
                                    <BiSolidDislike
                                      style={{
                                        width: "18px",
                                        height: "18px",
                                        color: "#954386",
                                      }}
                                    />
                                  ) : (
                                    <BiDislike
                                      style={{
                                        width: "18px",
                                        height: "18px",
                                        color: "#954386",
                                      }}
                                    />
                                  )}
                                </div>
                                <span>{item.forum_dislike_count}</span>
                              </div>

                              <div className="d-flex flex-row ms-4">
                                <Link to="/ForumView">
                                  {" "}
                                  <img src={com_icon} alt="like button" />
                                </Link>

                                <span>{item.forum_comment_count}</span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {/* {renderPagination()} */}
                    </TabPanel>

                    <TabPanel
                      header="My Forums"
                      onClick={HandleManageForumToggle}
                    >
                      {data.map((item, index) => {
                        return (
                          <div className="forum-item" key={index}>
                            <div className="forum-header">
                              <div className="forum-img">
                                <img src={forum_img_1} alt="" />
                              </div>
                              <div className="forum-title-box">
                                <div className="forum-title-wrapper">
                                  <p className="ft-main-title">
                                    {item.forum_title}
                                  </p>

                                  <div className="d-flex flex-row">
                                    <img
                                      src={{
                                        uri:
                                          ApiConfig.PROFILE_THEME +
                                          item.user_profile_pic,
                                      }}
                                    />
                                    <p className="ft-sub-title">
                                      {item.forum_created_by}
                                    </p>
                                    {"/"}
                                    <p className="ft-sub-title">
                                      {item.user_role}
                                    </p>
                                  </div>
                                  <p className="ft-sub-title">
                                    {item.category_name}
                                  </p>
                                  <p className="ft-date">
                                    {formatDate(item.forum_created_date)}
                                  </p>
                                </div>
                                <div className="forum-dropdown">
                                  <Dropdown className="Common_dropDown">
                                    <Dropdown.Toggle
                                      className="dropdown-btn"
                                      id="dropdown-basic"
                                    >
                                      <img src={dropdown_icons} alt="" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item>
                                        <div className="d-flex flex-row">
                                          <img src={share_icon} alt="" />
                                          <p>Share</p>
                                        </div>
                                      </Dropdown.Item>
                                      <Dropdown.Item>
                                        <div className="d-flex flex-row">
                                          <img src={edit_icon} alt="" />
                                          <p>Edit</p>
                                        </div>
                                      </Dropdown.Item>
                                      <Dropdown.Item>
                                        <div className="d-flex flex-row">
                                          <img src={Delete} alt="" />
                                          <p>Delete</p>
                                        </div>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>

                            <div className="forum-footer">
                              <div className="d-flex flex-row ">
                                {/* <div style={{ cursor: "pointer" }}>
                                  <img src={Likee} alt="like button" />
                                </div> */}

                                <div>
                                  <img src={like_btn} alt="like button" />
                                </div>

                                <p className="ms-0.5">
                                  {item.forum_like_count}
                                </p>
                              </div>
                              <div className="d-flex flex-row ms-4">
                                <div style={{ cursor: "pointer" }}>
                                  <img src={dislike_icon} alt="like button" />
                                </div>

                                {/* <div>
                                  <img src={dislike_btn} alt="like button" />
                                </div> */}

                                <p>{item.forum_dislike_count}</p>
                              </div>

                              <div className="d-flex flex-row ms-4">
                                <img src={com_icon} alt="like button" />
                                <p>{item.forum_comment_count}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {/* {renderPagination()} */}
                    </TabPanel>
                  </TabView>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Filter Modal Start */}
      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="common-filter-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="title-wrapper">
              <p>Filter By</p>
              <p onClick={handleClear} style={{ cursor: "pointer" }}>
                Clear
              </p>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {filterData.map((item) => (
            <div
              className="select-wrapper"
              key={item.id}
              onClick={() => handleclick(item.id)}
            >
              <ul>
                <li>
                  <input
                    type="radio"
                    id={`option-${item.id}`}
                    name="selector"
                    checked={selectedOption === item.id}
                    onChange={() => handleRadioChange(item.id)}
                  />
                  <label htmlFor={`option-${item.id}`}>{item.label}</label>
                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>
              </ul>
            </div>
          ))}
        </Modal.Body>
      </Modal>
      {/* Filter Modal End */}

      {/* Create Forum Modal Start */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Create Forum
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-form-wrapper">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmitone}
              id="myForm"
            >
              <div className="row">
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type Your Title Here..."
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      Please provide a title
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Category</Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        handleCatorylist(e.target.value);
                      }}
                    >
                      <option value="">Select Subject</option>
                      {categoryLists?.map((categories) => (
                        <option
                          key={categories.category_id}
                          value={categories.category_id}
                          id={categories.category_id}
                        >
                          {categories.category_name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      Please choose a category.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-md-12">
                  <Form.Group className="mb-3 " controlId="validationCustom01">
                    <Form.Label>Forum Text</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="Type Your Text Here..."
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide forum text.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-md-12">
                  <Form.Group
                    className="mb-3"
                    controlId="validationCustomAttachments"
                  >
                    <Form.Label>Attachments</Form.Label>
                    <Form.Control
                      type="file"
                      multiple
                      onChange={handleFileUpdate}
                    />
                    {file?.map((files, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>{files.name}</p>
                        <div onClick={() => handleDelete(index)}>
                          <IoIosClose />
                        </div>
                      </div>
                    ))}
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-wrapper border-top w-100">
            <button
              onClick={() => {
                setModalShow(false);
                Handleclearcreate();
              }}
              className="cx-btn-1"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="cx-btn-2"
              form="myForm"
              value="Update"
              onClick={() => setSection("create")}
            >
              Publish
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Create Forum Modal End */}

      {/* Edit Forum Modal Start */}
      <Modal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Edit Forum
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-form-wrapper">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmitone}
              id="myForm"
            >
              <div className="row">
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type Your Title Here..."
                      onChange={(e) => setTitle(e.target.value)}
                      value={title || isShownItem.forum_title}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a title.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Category</Form.Label>
                    <Form.Select
                      onChange={(e) => handleCatorylist(e.target.value)}
                    >
                      <option value={isShownItem.forum_category}>
                        {" "}
                        {isShownItem.category_name}{" "}
                      </option>
                      {categoryLists?.map((categories) => (
                        <option
                          key={categories.category_id}
                          value={categories.category_id}
                          id={categories.category_id}
                        >
                          {categories.category_name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please choose a category.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Forum Text</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type Your Text Here..."
                      onChange={(e) => setDescription(e.target.value)}
                      value={description || isShownItem.forum_description}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide forum text.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Attachments</Form.Label>
                    <Form.Control
                      type="file"
                      multiple
                      onChange={handleFileUpdate}
                    />
                    {file?.map((files, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>{files.name}</p>
                        <div onClick={() => handleDelete(index)}>
                          <IoIosClose />
                        </div>
                      </div>
                    ))}
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-wrapper border-top w-100">
            <button onClick={() => setModalShow1(false)} className="cx-btn-1">
              Cancel
            </button>
            <button
              type="submit"
              className="cx-btn-2"
              form="myForm"
              value="Update"
              onClick={() => {
                setSection("edit");
              }}
            >
              Update
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Edit Forum Modal End */}

      {/* Delete Modal Start */}
      <Modal
        show={modalShow2}
        onHide={() => setModalShow2(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Delete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-text-wrapper">
            Are you sure you want to delete ?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="delete-btn-wrapper border-top w-100">
            <button onClick={() => setModalShow2(false)} className="dlt-btn-1">
              Cancel
            </button>
            <button type="submit" className="dlt-btn-2" value="delete">
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Delete  Modal End */}
    </>
  );
}

export default Forum;
