import React, { useState } from "react";
import cross from "../../../assets/images/masterdatapic/cross.png";
const SubjectWeekend = (props) => {
  return (
    <div class="tt-col tt-content weekend">
      <div class="tt-desc tt-desc-inner">
        <img src={cross} />
        <div class="title">Weekend</div>
      </div>
    </div>
  );
};
export default SubjectWeekend;
