import React from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Routes,
  HashRouter,
} from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Dashboard from "../screens/Dashboard";
import DashboardAssignments from "./DashboardAssignments";
import DashboardAssessments from "./DashboardAssessments";
import Conference from "./Conference";
import CreateOfflineAssessment from "./OfflineAssesment/CreateNewOfflineAssesment.js";
import MyAttendance from "../screens/myattendance/MyAttendance";
import Announcement from "../screens/Communication/announcement/Announcement";
import AnnouncementNew from "./CommunicationNew/announcementnew/AnnouncementNew";
import ViewAnnouncementNew from "./CommunicationNew/announcementnew/ViewAnnouncementNew";
import EmailNew from "./CommunicationNew/Email/EmailNew";
import PushNotificationNew from "./CommunicationNew/PushNotification/PushNotificationNew";
import ViewPushNotificationNew from "./CommunicationNew/PushNotification/ViewPushNotificationNew";
import Chat from "./CommunicationNew/Chat/Chat";
import OfflineAssesmentChoose from "../screens/OfflineAssesment/OfflineAssesmentChoose.js";
import ViewEmailNew from "./CommunicationNew/Email/ViewEmailNew";
import Assignment from "./academics/Assignment/Assignment";
import CreateAssignment from "./academics/Assignment/CreateAssignment";
import AssignmentDetails from "../screens/academics/Assignment/AssignmentDetails";
import Assessment from "./academics//Assesment/Assessment";
import CreateAssessment from "./academics/Assesment/CreateAssessment";
import AssessmentDetails from "./academics/Assesment/AssessmentDetails";
import ChannelList from "./academics/Channel/ChannelList";
import Channel_Video from "./academics/Channel/Channel_Video";
import Syllabus from "./academics/syllabus/Syllabus";
import No_Attends_List from "./academics/Assesment/No_Attends_List";
import RankList from "./academics/Assesment/RankList";
import ScoreDetails from "../screens/academics/Assesment/ScoreDetails";
import EmpLeaves from "./Leaves/EmpLeaves";
import ToDo from "./to_do/To_do";
import TimeTable from "./timetable/TimeTable";
import VideoConference from "./VideoConference";
import VideoConferenceNew from "./VideoConferenceNew";
import Library from "./Library";
import SchoolTvChannel from "./SchoolTvChannel/SchoolTvChannel"; // School Tv Channel
import ScheduledPrograms from "./SchoolTvChannel/ScheduledPrograms";
import MyDiary from "./MyDiary/MyDiary"; // MyDiary
import NoteView from "./MyDiary/NoteView";
import Forum from "./Forum/Forum"; // Forum
import ForumView from "./Forum/ForumView";
import VideoPlaylist from "./SchoolTvChannel/VideoPlaylist";
import ApproveLeaves from "./Leaves/ApproveLeaves";
import AvailedLeaves from "./Leaves/AvailedLeaves";
import StudentAttendance from "./student/StudentAttendance";
import StudentLeaves from "./student/StudentLeaves";
import StudentList from "./student/StudentList";
import StudentCreateLeave from "./student/StudentCreateLeave";
import E_Learning from "./E_Learning/E_Learning";
import E_LearningDetails from "./E_Learning/E_LearningDetails/E_LearningDetails";
import ImgVideoGallary from "./E_Learning/ImgVideoGallary";
import MyResourses from "./E_Learning/E_LearningDetails/MyResources.js";
import ViewReviewedAssignment from "./academics/Assignment/AssignmentDetail/ViewReviewedAssignment";
import ReviewAssignment from "./academics/Assignment/AssignmentDetail/ReviewAssignment";
import ViewAssignmnet from "./academics/Assignment/AssignmentDetail/ViewAssignmnet";
import Attendance from "./myattendance/attendance/Attendance";
import EditProfile from "./Profile/EditProfile";
import UserProfile from "./Profile/UserProfile";
import MySetting from "./Profile/MySetting";
import LoginIn from "./login/LoginIn";
//import Login from "./login/Login";
import Notification from "./Profile/Notification";
import AddQuestion from "./academics/Assignment/AddQuestion";
import CreateQuestion from "./academics/Assignment/CreateQuestion";
import AssignmentDetails2 from "./academics/Assignment/AssignmentDetails2";
import ViewAnnouncement from "./Communication/announcement/ViewAnnouncement";
import Email from "./Communication/Email/Email";
import ViewEmail from "./Communication/Email/ViewEmail";
import Sms from "./Communication/Sms/Sms";
import ViewSms from "./Communication/Sms/ViewSms";
import PushNotification from "./Communication/PushNotification/PushNotification";
import ViewPushNotification from "./Communication/PushNotification/ViewPushNotification";
import AcademicCalender from "./academics/Calender/AcademicCalender";
import NewSyllabus from "./academics/syllabus/NewSyllabus";
import NewAttendance from "./myattendance/attendance/NewAttendance";
import Main_E_learning from "./E_Learnin_new/Main_E_learning";
import E_Learning_Details from "./E_Learnin_new/E_Learning_Details";
import Create_E_Learning from "./E_Learnin_new/Create_E_Learning";
import ImgVideoGallaryNew from "./E_Learnin_new/ImgVideoGallaryNew";
import Assessmentnew from "./academics/Assesment/Assessmentnew";
import OfflineAssesmentHome from "./OfflineAssesment/OfflineAssesmentHome.js";
import OfflineAssesment from "../screens/OfflineAssesment/OfflineAssesmentChoose.js";
import EditResource from "./E_Learnin_new/EditResource.js";
import ViewOfflineAssesment from "./OfflineAssesment/ViewOfflineAssesment.js";
import GradewiseList from "./OfflineAssesment/GradewiseList.js";
import EditOfflineAssessment from "./OfflineAssesment/EditOfflineAssesment.js";
import SubjectwiseList from "./OfflineAssesment/SubjectwiseList.js";
import EditSubjectwiseList from "./OfflineAssesment/EditSubjectwiseList.js";
import EditGradewiseList from "./OfflineAssesment/EditGradewiseList.js";
import CreateDiary from "./MyDiary/CreateDiary.js";
import TimeTableGenerate from "./timetable/TimeTableGenerate.js";
import { SyllabusCreate } from "./academics/syllabus/SyllabusCreate.js";
import { CreateSyllabus } from "./academics/syllabus/CreateSyllabus.js";
import { ViewSyllabus } from "./academics/syllabus/ViewSyllabus.js";
import { UpdateSyllabus } from "./academics/syllabus/UpdateSyllabus.js";
import AcademicCalendar from "./AcademicCalendar/AcademicCalendar.js";
import { StudentListNew } from "./Student_management/StudentList.js";
import { StudentDetails } from "./Student_management/StudentDetails.js";
import SyllabusProg from "./academics/syllabus/SyllabusProg.js";
import EditSyallabuss from "./academics/syllabus/EditSyallabuss.js";
import CreateSyllabusModals from "./academics/syllabus/CreateSyllabusModals.js";
import ViewSyllabusData from "./academics/syllabus/ViewSyllabusData.js";
import { Promotion } from "./Student_management/Promotion.js";
import { EvaluationType } from "./OfflineAssesment/EvaluationType.js";
import ViewEvaluationData from "./OfflineAssesment/ViewEvaluationData.js";
import AddEvalution from "./OfflineAssesment/AddEvalution.js";
import Breadcrumbs from "../components/BreadCrumbs.js";
import { MainGradeList } from "./OfflineAssesment/MainGradeList.js";
import { CreateGradeList } from "./OfflineAssesment/CreateGradeList.js";
import { GradeListEdit } from "./OfflineAssesment/GradeListEdit.js";
import { GradeListView } from "./OfflineAssesment/GradeListView.js";
import LessonTopicAssessmentHome from "./LessonTopicAssessment/LessonTopicAssessmentHome.js";
import LessonTopicAssessmentSubject from "./LessonTopicAssessment/LessonTopicAssessmentSubject.js";
import StudentMarkList from "./LessonTopicAssessment/StudentMarkList.js";
import LessonTopicAssessmentSettings from "./LessonTopicAssessment/LessonTopicAssessmentSettings.js";
import StudentMarkListEdit from "./LessonTopicAssessment/StudentMarkListEdit.js";
import LessonTopicAssessmentEdit from "./LessonTopicAssessment/LessonTopicAssessmentEdit.js";
import Reports from "./LessonTopicAssessment/Reports.js";
import Subjects from "./masterdata/Subjects.js";
import EditSubjects from "./masterdata/EditSubjects.js";
import CreateSubjects from "./masterdata/CreateSubjects.js";
import ImportSubjects from "./masterdata/ImportSubjects.js";
import Teachers from "./masterdata/Teachers.js";
import TeacherRegisteration from "./masterdata/TeacherRegisteration.js";
import CreateTeacher from "./masterdata/CreateTeacher.js";
import TeacherDetailsView from "./masterdata/TeacherDetailsView.js";
import EditTeacher from "./masterdata/EditTeacher.js";
import ChangePassword from "./masterdata/ChangePassword.js";
import TeacherImport from "./masterdata/TeacherImport.js";
import Feed from "./Feed/Feed.js";
import FeedView from "./Feed/FeedView.js";
import { CreateFeed } from "./Feed/CreateFeed.js";
import { FeedEdit } from "./Feed/FeedEdit.js";
import SearchBook from "./SearchBook.js";

function Layout() {
  return (
    //  Page Body Start
    <div className="page-body-wrapper sidebar-icon">
      <BrowserRouter>
        <Header />
        <div className="page-body" id="page_container">
          <Breadcrumbs />

          <Routes>
            <Route exact path="/" element={<Dashboard />} />
            <Route
              path="/dashboard/assignments"
              element={<DashboardAssignments />}
            />
            <Route
              path="/dashboard/assessments"
              element={<DashboardAssessments />}
            />
            <Route path="/conference" element={<Conference />} />
            {/* <Route path="/teacher/my-attendance" element={<MyAttendance />} /> */}
            <Route path="/my-attendance" element={<MyAttendance />} />
            <Route path="/Announcement" element={<Announcement />} />
            <Route path="/AnnouncementNew" element={<AnnouncementNew />} />
            <Route
              path="/ViewAnnouncementNew"
              element={<ViewAnnouncementNew />}
            />
            <Route path="/EmailNew" element={<EmailNew />} />
            <Route path="/ViewEmailNew" element={<ViewEmailNew />} />
            <Route
              path="/PushNotificationNew"
              element={<PushNotificationNew />}
            />
            <Route
              path="/ViewPushNotificationNew"
              element={<ViewPushNotificationNew />}
            />
            <Route path="/Chat" element={<Chat />} />
            <Route path="/Email" element={<Email />} />
            <Route path="/sms" element={<Sms />} />
            <Route path="/push-notification" element={<PushNotification />} />
            <Route path="/view-sms" element={<ViewSms />} />
            <Route
              path="/view-push-notification"
              element={<ViewPushNotification />}
            />
            <Route path="/ViewAnnouncement" element={<ViewAnnouncement />} />
            <Route path="/view-email" element={<ViewEmail />} />
            <Route path="/Assessment" element={<Assessmentnew />} />
            <Route path="/create-assessment" element={<CreateAssessment />} />
            <Route
              path="/create-assessment/:id"
              element={<CreateAssessment />}
            />
            <Route
              path="/AssessmentDetails/:id"
              element={<AssessmentDetails />}
            />
            <Route
              path="/ViewOfflineAssesment"
              element={<ViewOfflineAssesment />}
            />
            <Route
              path="/ViewOfflineAssesment/:assignmentId"
              element={<ViewOfflineAssesment />}
            />

            <Route path="/GradewiseList" element={<GradewiseList />} />

            <Route
              path="/LessonTopicAssessmentSettings"
              element={<LessonTopicAssessmentSettings />}
            />
            <Route
              path="/EditOfflineAssessment"
              element={<EditOfflineAssessment />}
            />
            <Route path="/EditGradewiseList" element={<EditGradewiseList />} />

            <Route
              path="/OfflineAssesmentHome"
              element={<OfflineAssesmentHome />}
            />
            <Route
              path="/LessonTopicAssessmentHome"
              element={<LessonTopicAssessmentHome />}
            />
            <Route
              path="/LessonTopicAssessmentEdit"
              element={<LessonTopicAssessmentEdit />}
            />
            <Route path="/GradeList" element={<GradeListView />} />
            <Route path="/maingradelist" element={<MainGradeList />} />

            <Route
              path="/CreateOfflineAssessment"
              element={<CreateOfflineAssessment />}
            />
            <Route
              path="/CreateOfflineAssessment/:TermId"
              element={<CreateOfflineAssessment />}
            />

            <Route
              path="/OfflineAssesmentChoose"
              element={<OfflineAssesmentChoose />}
            />
            <Route
              path="/LessonTopicAssessmentSubject"
              element={<LessonTopicAssessmentSubject />}
            />

            <Route path="/SubjectwiseList" element={<SubjectwiseList />} />
            <Route path="/StudentMarkList" element={<StudentMarkList />} />
            <Route path="/createGradelist" element={<CreateGradeList />} />
            <Route path="/GradelistEdit" element={<GradeListEdit />} />

            <Route
              path="/EditSubjectwiseList"
              element={<EditSubjectwiseList />}
            />
            <Route
              path="/StudentMarkListEdit"
              element={<StudentMarkListEdit />}
            />
            <Route path="/EvaluationType" element={<EvaluationType />} />
            <Route path="/ViewEvaluation" element={<ViewEvaluationData />} />
            <Route path="/AddEvalution" element={<AddEvalution />} />
            <Route path="/Reports" element={<Reports />} />
            <Route path="/ChannelList" element={<ChannelList />} />
            <Route path="/Channel_Video" element={<Channel_Video />} />
            <Route path="/Syllabus" element={<Syllabus />} />
            <Route path="/NewSyllabus" element={<NewSyllabus />} />
            <Route path="/SyllabusCreate" element={<SyllabusCreate />} />
            <Route path="/CreateSyllabus" element={<CreateSyllabus />} />
            <Route path="/ViewSyllabus" element={<ViewSyllabus />} />
            <Route path="/calendar" element={<AcademicCalender />} />
            <Route path="/UpdateSyllabus" element={<UpdateSyllabus />} />
            {/* <Route path="Assignment" element={<Assignment />} /> */}
            <Route path="/Assignment" element={<Assignment />} />
            <Route path="/create-assignment" element={<CreateAssignment />} />
            <Route
              path="/create-assignment/:id"
              element={<CreateAssignment />}
            />
            <Route
              path="/AssignmentDetails/:id"
              element={<AssignmentDetails />}
            />
            <Route path="/No_Attends_List" element={<No_Attends_List />} />
            <Route path="/RankList" element={<RankList />} />
            <Route path="/ScoreDetails/:id" element={<ScoreDetails />} />
            <Route path="/EmpLeaves" element={<EmpLeaves />} />
            <Route path="/todo" element={<ToDo />} />
            <Route path="/timetable" element={<TimeTable />} />
            <Route path="/AcademicCalendar" element={<AcademicCalendar />} />

            <Route path="/TimeTableGenerate" element={<TimeTableGenerate />} />

            <Route path="/ApproveLeaves" element={<ApproveLeaves />} />
            <Route path="/AvailedLeaves" element={<AvailedLeaves />} />
            <Route path="/student-attendance" element={<StudentAttendance />} />
            <Route path="/studentAttendance" element={<NewAttendance />} />
            <Route path="/StudentLeaves" element={<StudentLeaves />} />
            <Route path="/StudentList" element={<StudentList />} />
            <Route
              path="/StudentCreateLeave"
              element={<StudentCreateLeave />}
            />
            {/* <Route path="/E_Learning" element={<E_Learning />} /> */}
            <Route path="/E_Learning" element={<Main_E_learning />} />
            {/* <Route path="/E_LearningDetails" element={<E_LearningDetails />} /> */}
            <Route path="/E_LearningDetails" element={<E_Learning_Details />} />
            <Route path="/Create_E_Learning" element={<Create_E_Learning />} />
            <Route path="/EditResource" element={<EditResource />} />
            <Route
              path="/ImgVideoGallaryNew/:id/:studentId"
              element={<ImgVideoGallaryNew />}
            />
            <Route
              path="/ImgVideoGallaryNew/:id"
              element={<ImgVideoGallaryNew />}
            />
            <Route path="/MyResourses" element={<MyResourses />} />
            <Route
              path="/ViewReviewedAssignment"
              element={<ViewReviewedAssignment />}
            />
            <Route
              path="/ViewReviewedAssignment/:student_id/:idy"
              element={<ViewReviewedAssignment />}
            />
            <Route path=":isFromReview" element={<ViewReviewedAssignment />} />

            <Route
              path="/ReviewAssignment/:idy/:student_id"
              element={<ReviewAssignment />}
            />
            <Route
              path="/ViewAssignmnet/:student_id/:idy"
              element={<ViewAssignmnet />}
            />
            <Route path="/Attendance" element={<Attendance />} />
            <Route path="/UserProfile" element={<UserProfile />} />
            <Route path="/EditProfile" element={<EditProfile />} />
            <Route path="/MySetting" element={<MySetting />} />
            {/*<Route path="/Login" element={<Login />} />*/}
            <Route path="/LoginIn" element={<LoginIn />} />
            <Route path="/Notification" element={<Notification />} />
            <Route path="/AddQuestion" element={<AddQuestion />} />
            <Route
              path="/create-assessment/CreateQuestion/:id"
              element={<CreateQuestion />}
            />
            <Route
              path="/create-assessment/:id/CreateQuestion/:id"
              element={<CreateQuestion />}
            />
            <Route path="/video-conference" element={<VideoConference />} />
            <Route path="/Library" element={<Library />} />
            <Route path="/SchoolTvChannel" element={<SchoolTvChannel />} />
            <Route path="/ScheduledPrograms" element={<ScheduledPrograms />} />
            <Route path="/VideoPlaylist" element={<VideoPlaylist />} />
            <Route path="/MyDiary" element={<MyDiary />} />
            <Route path="/NoteView" element={<NoteView />} />
            <Route path="/CreateDiary" element={<CreateDiary />} />
            <Route path="/Forum" element={<Forum />} />
            <Route path="/ForumView" element={<ForumView />} />
            <Route
              path="/VideoConferenceNew"
              element={<VideoConferenceNew />}
            />

            {/*================ student list ================= */}
            <Route path="/Studentlistnew" element={<StudentListNew />} />
            <Route path="/Studentdetails" element={<StudentDetails />} />
            <Route path="/Promotion" element={<Promotion />} />

            {/* ------- */}
            {/* <----------------------- Syllabus pages------------------------------> */}
            <Route path="/syllabus-prog" element={<SyllabusProg />} />
            <Route path="/syllabus-Edit" element={<EditSyallabuss />} />
            <Route path="/ViewSyllabusData" element={<ViewSyllabusData />} />

            <Route
              path="/create-Syllabus-modals"
              element={<CreateSyllabusModals />}
            />
            <Route
              path="/AssignmentDetails2"
              element={<AssignmentDetails2 />}
            />
            {/* <----------------------- Master Data------------------------------> */}

            <Route path="/Subjects" element={<Subjects />} />
            <Route path="/EditSubjects" element={<EditSubjects />} />
            <Route path="/CreateSubjects" element={<CreateSubjects />} />
            <Route path="/ImportSubjects" element={<ImportSubjects />} />
            <Route path="/Teachers" element={<Teachers />} />
            <Route
              path="/TeacherRegisteration"
              element={<TeacherRegisteration />}
            />
            <Route path="/CreateTeacher" element={<CreateTeacher />} />
            <Route
              path="/TeacherDetailsView"
              element={<TeacherDetailsView />}
            />
            <Route path="/EditTeacher" element={<EditTeacher />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />
            <Route path="/TeacherImport" element={<TeacherImport />} />
            {/* =====================Feed================================= */}
            <Route path="/Feed" element={<Feed />} />
            <Route path="/feedView" element={<FeedView />} />
            <Route path="/feedCreate" element={<CreateFeed />} />
            <Route path="/feedEdit" element={<FeedEdit />} />

            <Route path="/search-results" element={<SearchBook />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
    //Page Body End here
  );
}

export default Layout;
