import React, { useState } from "react";
import profilePic from "../../assets/images/masterdatapic/profilePic.png";
import Params from "../../config/Params";

function TeacherRegisteration() {
  const [fullname, setFullName] = useState("");
  const [registeremail, setRegisterEmail] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");

  const clearFields = () => {
    setFullName("");
    setRegisterEmail("");
    setPhoneNumber("");
  };
  const registerteacher = [
    {
      slno: "1",
      fullname: "Shaikh Ashmer Shaikh",
      teacheremail: "ashick@mailinator.com",
      Rnumber: "5588554455",
      Rdesignation: "Professor",
    },
    {
      slno: "2",
      fullname: "Ashick",
      teacheremail: "legel17394@subdito.com",
      Rnumber: "9172102422",
      Rdesignation: "Software Engineer",
    },
    {
      slno: "3",
      fullname: "Teacher Test",
      teacheremail: "normanb@mailinator.com",
      Rnumber: "5588221144",
      Rdesignation: "Teacher",
    },
  ];

  return (
    <>
      <div className="row edsys-table">
        <div className="col-sm-12">
          <div className="d-flex">
            <h5 className="mb-2 flex-grow-1">Registered Teacher</h5>
          </div>

          <div className="card">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Full Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Contact Number</th>
                    <th scope="col">Designation</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th></th>
                    <th>
                      <input
                        className="form-control"
                        type="Search"
                        placeholder="Search"
                        value={fullname}
                        onChange={(e) => setFullName(e.target.value)}
                      ></input>
                    </th>
                    <th>
                      <input
                        className="form-control"
                        type="Search"
                        placeholder="Search"
                        value={registeremail}
                        onChange={(e) => setRegisterEmail(e.target.value)}
                      ></input>
                    </th>
                    <th>
                      <input
                        className="form-control"
                        type="Search"
                        placeholder="Search"
                        value={phonenumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      ></input>
                    </th>
                    <th></th>
                    <th>
                      <div className="button_div">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={clearFields}
                        >
                          Clear
                        </button>
                      </div>
                    </th>
                  </tr>
                  {registerteacher.map((item, index) => {
                    return (
                      <tr>
                        <th scope="row">{item.slno}</th>
                        <td>
                          <div className="subject_image_div">
                            <img src={profilePic} className="subject_image" />
                            {item.fullname}
                          </div>
                        </td>
                        <td>{item.teacheremail}</td>
                        <td>{item.Rnumber}</td>
                        <td>{item.Rdesignation}</td>
                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TeacherRegisteration;
