import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import book_1 from "../assets/images/book_1.svg";
import book_2 from "../assets/images/book_2.svg";
import book_3 from "../assets/images/book_3.svg";
import book_4 from "../assets/images/book_4.svg";
import book_5 from "../assets/images/book_5.svg";
import book_6 from "../assets/images/book_6.svg";
import book_7 from "../assets/images/book_7.svg";
import book_8 from "../assets/images/book_8.svg";
import book_9 from "../assets/images/book_9.svg";
import search_icon from "../assets/images/search_icon.svg";
import Params from "../config/Params";
import { simplePostCall } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import axios from "axios";
import cross_icon from "../assets/images/masterdatapic/cross.png";
import { useNavigate } from "react-router-dom";
import SearchBook from "./SearchBook";

function Library() {
  const [q, setQ] = useState("");
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [search, setSearch] = useState([]);

  const [mybooks, setMybooks] = useState([]);
  const [bookHistory, setBookHistory] = useState([]);
  const [myQueue, setMyQueue] = useState([]);
  const [searchBook, setSearchBook] = useState([]);
  const [messages, setMessages] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);

  const navigate = useNavigate();

  const myBooksAPI = async () => {
    axios(ApiConfig.MYBOOKS_LIST, {
      method: "POST",
      data: {
        teacher_id: Params.teacher_id,
      },
      headers: {
        Accept: "application/json",
        api_key: Params.api_key,
        customer_id: Params.customer_id,
      },
    })
      .then((response) => {
        if (response.data.result) {
          setMybooks(response.data.list);
          console.log(response.data, "this is data");
        }
      })
      .catch((err) => {
        console.log("home error: ", err);
      });
  };
  const bookHistoryAPI = async () => {
    axios(ApiConfig.BOOK_HISTORY, {
      method: "POST",
      data: {
        teacher_id: Params.teacher_id,
        search: "",
      },
      headers: {
        Accept: "application/json",
        api_key: Params.api_key,
        customer_id: Params.customer_id,
      },
    })
      .then((response) => {
        if (response.data.result) {
          setBookHistory(response.data.list);
          console.log(response.data, "this is history data");
        }
      })
      .catch((err) => {
        console.log("home error: ", err);
      });
  };
  const myQueueAPI = async (bookId, status) => {
    axios(ApiConfig.MY_QUEUE, {
      method: "POST",
      data: {
        teacher_id: Params.teacher_id,
        search: "",
        book_id: bookId,
        status: status,
      },
      headers: {
        Accept: "application/json",
        api_key: Params.api_key,
        customer_id: Params.customer_id,
      },
    })
      .then((response) => {
        if (response.data.result) {
          setMyQueue(response.data.list);
          console.log(response.data, "this is queue data");
        } else {
          setMessages(response.data.message);
          console.log(response.data.message, "ver");
        }
      })
      .catch((err) => {
        console.log("home error: ", err);
      });
  };
  const searchAPI = async () => {
    axios(ApiConfig.SEARCH_BOOK, {
      method: "POST",
      data: {
        teacher_id: Params.teacher_id,
        search: search,
      },
      headers: {
        Accept: "application/json",
        api_key: Params.api_key,
        customer_id: Params.customer_id,
      },
    })
      .then((response) => {
        if (response.data.result) {
          setSearchBook(response.data.list);
          console.log(response.data, "this is search data");
          setShowSearchResults(true);
          navigate("/search-results", {
            state: { searchResults: response.data.list, searchQuery: search },
          });
        }
      })
      .catch((err) => {
        console.log("home error: ", err);
      });
  };

  useEffect(() => {
    myBooksAPI();
    bookHistoryAPI();
    myQueueAPI();
  }, []);

  const handleCancelQueue = (bookId) => {
    const status = "queued";

    myQueueAPI(bookId, status);
    myQueueAPI();
  };

  if (error) {
    return <p>{error.message}</p>;
  } else {
    return (
      <>
        <div class="library announcementnew">
          <div className="main-section">
            <div className="row">
              <div className="col-md-12">
                <div className="announcement-top">
                  <label>Library</label>
                  <div className="search-wrapper">
                    <input
                      type="text"
                      placeholder="Search Book"
                      name="search-form"
                      id="search-form"
                      className="search-input"
                      onChange={(e) => {
                        setSearch(e.target.value);
                        console.log(e.target.value, "Serach");
                      }}
                    />
                    <img src={search_icon} alt="" onClick={searchAPI} />
                  </div>
                </div>
              </div>
              {showSearchResults ? (
                <div className="search-results">
                  <SearchBook />
                </div>
              ) : (
                <>
                  <div className="col-md-12">
                    <div className="books-main-wrapper">
                      <TabView>
                        <TabPanel header="My Books">
                          <div className="my-books">
                            {q === "" ? (
                              ""
                            ) : (
                              <p className="search-result">Search Results</p>
                            )}
                            <div className="row">
                              {mybooks.map((item) => (
                                <>
                                  {/* <span></span> */}
                                  <div className="col-md-6 col-lg-4 col-sm-12">
                                    <div className="book-card">
                                      <div className="row">
                                        <div className="col-md-4">
                                          <div className="bc-img-box">
                                            <img
                                              className=""
                                              src={
                                                item.book_image
                                                  ? ApiConfig.NODE_URL +
                                                    "/" +
                                                    item.book_image
                                                  : book_1
                                              }
                                              alt="Description"
                                              style={{
                                                height: "100px",
                                                width: "100px",
                                                border: "none",
                                                boxShadow: "none",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-8">
                                          <p className="bc-title">
                                            {item.book_name}
                                          </p>
                                          <p className="bc-author">
                                            By : {item.author_name}
                                            <span
                                              className={item.fineClass}
                                              style={{ color: "red" }}
                                            >
                                              Fine : {item.fine}
                                            </span>
                                          </p>
                                          <p
                                            className={item.statusClass1}
                                            style={{ color: "red" }}
                                          >
                                            Issued Date : {item.book_issue_date}
                                          </p>
                                          <p
                                            className={item.statusClass2}
                                            style={{ color: "red" }}
                                          >
                                            Due Date : {item.book_due_date}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </div>
                            {q === "" ? (
                              ""
                            ) : (
                              <div className="row">
                                <p className="recommended">Recommended Books</p>
                                <div className="col-md-4">
                                  <div className="book-card">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="bc-img-box">
                                          <img src={book_4} alt="" />
                                        </div>
                                      </div>
                                      <div className="col-md-8">
                                        <p className="bc-title">
                                          Smart English 9th
                                        </p>
                                        <p className="bc-author">
                                          By Manjit Singh
                                        </p>
                                        <p className="date-green"></p>
                                        <p className="date-green">
                                          Available{" "}
                                          <span className="available">
                                            2/24
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="book-card">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="bc-img-box">
                                          <img src={book_5} alt="" />
                                        </div>
                                      </div>
                                      <div className="col-md-8">
                                        <p className="bc-title">
                                          Smart English 9th
                                        </p>
                                        <p className="bc-author">
                                          By Manjit Singh
                                        </p>
                                        <p className="date-green"></p>
                                        <p className="date-green">
                                          Available{" "}
                                          <span className="available">
                                            2/24
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="book-card">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="bc-img-box">
                                          <img src={book_6} alt="" />
                                        </div>
                                      </div>
                                      <div className="col-md-8">
                                        <p className="bc-title">
                                          Smart English 9th
                                        </p>
                                        <p className="bc-author">
                                          By Manjit Singh
                                        </p>
                                        <p className="date-green"></p>
                                        <p className="date-green">
                                          Available{" "}
                                          <span className="available">
                                            2/24
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </TabPanel>

                        <TabPanel header="Book History">
                          <div className="my-books">
                            <div className="row">
                              {bookHistory.map((item) => (
                                <>
                                  {/* <span></span> */}
                                  <div className="col-md-6 col-lg-4 col-sm-12">
                                    <div className="book-card">
                                      <div className="row">
                                        <div className="col-md-4">
                                          <div className="bc-img-box">
                                            <img
                                              className=""
                                              src={
                                                item.book_image
                                                  ? ApiConfig.NODE_URL +
                                                    "/" +
                                                    item.book_image
                                                  : book_2
                                              }
                                              alt="Description"
                                              style={{
                                                // height: "100px",
                                                // width: "100px",
                                                border: "none",
                                                boxShadow: "none",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-8">
                                          <p className="bc-title">
                                            {item.book_name}
                                          </p>
                                          <p className="bc-author">
                                            By {item.author_name}
                                            {/* <span
                                          className={item.fineClass}
                                          style={{ color: "red" }}
                                        >
                                          Fine : {item.fine}
                                        </span> */}
                                          </p>
                                          <p
                                            className={item.statusClass1}
                                            style={{ color: "green" }}
                                          >
                                            Issued Date : {item.book_issue_date}
                                          </p>
                                          <p
                                            className={item.statusClass2}
                                            style={{ color: "green" }}
                                          >
                                            Return Date :{" "}
                                            {item.book_return_date}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel header="Queue">
                          <div className="my-books">
                            {messages ? (
                              <div className="d-flex justify-content-center align-items-center">
                                <h4 style={{ color: "red", fontSize: "20px" }}>
                                  {messages}
                                </h4>
                              </div>
                            ) : (
                              <div className="row">
                                {myQueue.map((item) => (
                                  <>
                                    {/* <span></span> */}
                                    <div className="col-md-6 col-lg-4 col-sm-12">
                                      <div className="book-card">
                                        <div className="row">
                                          <div className="col-md-4">
                                            <div className="bc-img-box">
                                              <img
                                                className=""
                                                src={
                                                  item.book_image
                                                    ? ApiConfig.NODE_URL +
                                                      "/" +
                                                      item.book_image
                                                    : book_3
                                                }
                                                alt="Description"
                                                style={{
                                                  // height: "100px",
                                                  // width: "100px",
                                                  border: "none",
                                                  boxShadow: "none",
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-md-8">
                                            <p className="bc-title">
                                              {item.book_name}
                                            </p>
                                            <p className="bc-author">
                                              By {item.author_name}
                                              {/* <span
                                          className={item.fineClass}
                                          style={{ color: "red" }}
                                        >
                                          Fine : {item.fine}
                                        </span> */}
                                            </p>
                                            <p
                                              className={item.statusClass1}
                                              style={{ color: "blue" }}
                                            >
                                              Q Position : {item.position}
                                              <img
                                                src={cross_icon}
                                                alt=""
                                                style={{
                                                  width: "15px",
                                                  height: "15px",
                                                }}
                                                onClick={() =>
                                                  handleCancelQueue(
                                                    item.book_id
                                                  )
                                                }
                                              />
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                              </div>
                            )}
                          </div>
                        </TabPanel>
                      </TabView>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Library;
