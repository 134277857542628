import React, { useEffect, useState } from "react";
import Select from "react-select";
import SubjectTimeTableGrid from "./subjecttimetable/SubjectTimeTableGrid";
import { toast } from "react-toastify";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";

const initialState = {
  isloading: false,
  subject_code: "",
  subject_name: "",
  subject_type: "",
  subject_icon: "",
  subject_code_error: "",
  subject_name_error: "",
  subject_type_error: "",
};

function CreateSubjects() {
  const [state, setState] = useState(initialState);
  const [subjecttypeState, setSubjecttypeState] = useState({
    subject_type: [
      { value: "Optional", label: "Optional" },
      { value: "Mandatory", label: "Mandatory" },
    ],
  });
  const [showImages, setShowImages] = useState([]);
  const [imageCreate, setImageCreate] = useState("");

  const Periods = [
    {
      id: 1,
      PeriodId: 1,
      startTime: "09:30 AM",
      endTime: "10:15 AM",
      isInterval: false,
    },
    {
      id: 2,
      PeriodId: 2,
      startTime: "10:15 AM",
      endTime: "11:00 AM",
      isInterval: false,
    },
    {
      id: 3,
      PeriodId: 0,
      startTime: "11:00 AM",
      endTime: "11:15 AM",
      isInterval: true,
    },
    {
      id: 4,
      PeriodId: 3,
      startTime: "11:15 AM",
      endTime: "12:00 PM",
      isInterval: false,
    },
    {
      id: 5,
      PeriodId: 4,
      startTime: "12:00 PM",
      endTime: "12:45 PM",
      isInterval: false,
    },
    {
      id: 6,
      PeriodId: 0,
      startTime: "12:45 PM",
      endTime: "01:45 PM",
      isInterval: true,
    },
    {
      id: 7,
      PeriodId: 5,
      startTime: "01:45 PM",
      endTime: "02:30 PM",
      isInterval: false,
    },
    {
      id: 8,
      PeriodId: 6,
      startTime: "02:30 PM",
      endTime: "03:15 PM",
      isInterval: false,
    },
    {
      id: 9,
      PeriodId: 0,
      startTime: "03:15 PM",
      endTime: "03:30 PM",
      isInterval: true,
    },
    {
      id: 10,
      PeriodId: 7,
      startTime: "03:30 PM",
      endTime: "04:15 PM",
      isInterval: false,
    },
  ];

  const lectures = [
    {
      id: 1,
      Period: 1,
      subject: "Physics",
      name: "Mary Ann",
      imgPath: "assets/images/masterdatapic/question.png",
    },
    {
      id: 2,
      Period: 2,
      subject: "Mathematics",
      name: "Mary Ann",
    },
    {
      id: 3,
      Period: 3,
      subject: "Chemistry",
      name: "Mary Ann",
    },
    {
      id: 4,
      Period: 4,
      subject: "Biology",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
    {
      id: 5,
      Period: 5,
      subject: "History",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
    {
      id: 6,
      Period: 6,
      subject: "English",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
    {
      id: 7,
      Period: 7,
      subject: "English",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
  ];
  const WeeklyPeriods = [
    {
      id: 1,
      Period: 1,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 2,
      Period: 1,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 3,
      Period: 1,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 4,
      Period: 1,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 5,
      Period: 1,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 6,
      Period: 1,
      subject: "Mathematics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //2nd Period
    {
      id: 7,
      Period: 2,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 8,
      Period: 2,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 9,
      Period: 2,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 10,
      Period: 2,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 11,
      Period: 2,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 12,
      Period: 2,
      subject: "Mathematics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //3rd Period
    {
      id: 13,
      Period: 3,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 14,
      Period: 3,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 15,
      Period: 3,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 16,
      Period: 3,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 17,
      Period: 3,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 18,
      Period: 3,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //4th Period
    {
      id: 19,
      Period: 4,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 20,
      Period: 4,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 21,
      Period: 4,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 22,
      Period: 4,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 23,
      Period: 4,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 24,
      Period: 4,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //5th Period
    {
      id: 25,
      Period: 5,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 26,
      Period: 5,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 27,
      Period: 5,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 28,
      Period: 5,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 29,
      Period: 5,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 29,
      Period: 5,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //6th Period
    {
      id: 30,
      Period: 6,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 31,
      Period: 6,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 32,
      Period: 6,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 33,
      Period: 6,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 34,
      Period: 6,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 35,
      Period: 6,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //7th Period
    {
      id: 36,
      Period: 7,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 37,
      Period: 7,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 38,
      Period: 7,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 39,
      Period: 7,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 40,
      Period: 7,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 41,
      Period: 7,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
  ];

  const dataGrid = {
    Periods: Periods,
    Lectures: WeeklyPeriods,
  };
  function handleSaveAndNext() {
    try {
      if (
        state.subject_code === "" ||
        state.subject_name === "" ||
        state.subject_type === ""
      ) {
        toast.error("Please fill all mandatory fields");

        setState(() => ({
          subject_code_error:
            state.subject_code === "" ? "Enter subject Code" : null,
          subject_name_error:
            state.subject_name === "" ? "Enter Subject Name" : null,
          subject_type_error: state.subject_type === "" ? "Select Type" : null,
        }));
      } else {
        CreateMode();
        setState("");
      }
    } catch (error) {
      console.error("Error in handleSaveAndNext:", error);
    }
  }
  function onSubjecttypeChange(selectedOption) {
    setState({
      ...state,
      subject_type: selectedOption.value,
      subjecttypeState: selectedOption,
      subject_type_error: setState(""),
    });
  }
  const CreateMode = () => {
    let requestbody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      subject_code: state.subject_code,
      subject_name: state.subject_name,
      subject_type: state.subject_type,
      subject_icon: imageCreate,
    });
    simplePostCall(ApiConfig.SUBJECT_CREATE, requestbody)
      .then((data) => {
        console.log(data, "createvannu");
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  useEffect(() => {
    IconCreate();
  }, []);
  const IconCreate = () => {
    let requestIcon = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.SUBJECT_IMAGE, requestIcon)
      .then((data) => {
        setShowImages(data?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  return (
    <>
      <div className="row edsys-table">
        <div className="col-sm-12">
          <div className="d-flex">
            <h5 className="mb-2 flex-grow-1">Create Subjects</h5>
          </div>
          <div className="card">
            <div className="card-header">
              <form
                className="form-bookmark needs-validation"
                id="bookmark-form"
                novalidate=""
              >
                <div className="form-row">
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">Subject Code</label>
                      <input
                        className="datepicker-here form-control digits"
                        type="text"
                        value={state.subject_code}
                        onChange={(e) =>
                          setState({
                            ...state,
                            subject_code: e.target.value,
                            subject_code_error: setState(""),
                          })
                        }
                      />
                      <small
                        style={{
                          color: "red",
                          display: state.subject_code_error ? "" : "none",
                        }}
                      >
                        {state.subject_code_error}
                      </small>
                    </div>

                    <div className="form-group col-md-6">
                      <label for="task-title">Subject Name</label>
                      <input
                        className="datepicker-here form-control digits"
                        type="text"
                        value={state.subject_name}
                        onChange={(e) => {
                          setState({
                            ...state,
                            subject_name: e.target.value,
                            subject_name_error: setState(""),
                          });
                        }}
                      />
                      <small
                        style={{
                          color: "red",
                          display: state.subject_name_error ? "" : "none",
                        }}
                      >
                        {state.subject_name_error}
                      </small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">Type</label>

                      <Select
                        onChange={onSubjecttypeChange}
                        options={subjecttypeState.subject_type}
                        placeholder={state.subject_type}
                      />
                      <small
                        style={{
                          color: "red",
                          display: state.subject_type_error ? "" : "none",
                        }}
                      >
                        {state.subject_type_error}
                      </small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">Select Subject Icon</label>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      overflow: "hidden",
                      overflowY: "scroll",
                      height: "200px",
                    }}
                  >
                    {showImages.map((item) => (
                      <div className="col-lg-2">
                        <div
                          className="image_div"
                          onClick={() => setImageCreate(item.subject_icon_url)}
                        >
                          <img
                            src={ApiConfig.NODE_URL + item.subject_icon_url}
                            width={60}
                            height={60}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div style={{ marginTop: "50px" }}>
                    <SubjectTimeTableGrid data={dataGrid} />
                  </div>
                </div>
              </form>
              <div className="schedulebutton">
                <div></div>
                <div className="">
                  <button
                    onClick={() => {
                      handleSaveAndNext();
                    }}
                    className="btn btn-primary"
                    type="button"
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateSubjects;
