import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import profilePic from "../../assets/images/masterdatapic/profilePic.png";
import ReactFlagsSelect from "react-flags-select";
import Select from "react-select";

function TeacherDetailsView() {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showblockModal, setShowBlockModal] = useState(false);
  const [showresignModal, setShowResignModal] = useState(false);
  const [country, setCountry] = useState("");

  const teacherdetails = [
    {
      email: "ashick@mailinator.com",
      phone: "+91 5588554455",
      status: "Verified",
    },
  ];
  const teachersubject = [
    {
      teachernum: "1",
      gradeanddivision: "10 A",
      subjectname: "Maths , Social Science",
    },
  ];
  const gender = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Others", label: "Others" },
  ];
  return (
    <>
      <div className="row edsys-table">
        <div className="col-sm-12">
          <div className="d-flex">
            <h5 className="mb-2 flex-grow-1">Teacher Details</h5>
            <div className="tablebuttonview">
              <a
                href="/EditTeacher"
                type="button"
                className="btn timetablebutton "
              >
                <span>Edit</span>
              </a>
              <a
                href="/ChangePassword"
                type="button"
                className="btn timetablebutton "
              >
                <span>Change Password</span>
              </a>
              <a
                type="button"
                className="btn timetablebutton"
                onClick={() => setShowBlockModal(true)}
              >
                <span>Block</span>
              </a>

              <a
                type="button"
                className="btn timetablebutton"
                onClick={() => setShowResignModal(true)}
              >
                <span>Resigned</span>
              </a>
              <a
                type="button"
                className="btn timetablebutton"
                onClick={() => setShowDeleteModal(true)}
              >
                <span>Delete</span>
              </a>
            </div>
          </div>
          <br />
          <div className="row card-div">
            <div className="card col-md-5">
              <div className="card-header">
                <div className="teacher_profile_div">
                  <img src={profilePic} className="teacher_profile" />
                  <label for="task-title">Ashick</label> <br />
                </div>
                <h6 className="mb-2 flex-grow-1">Contact Information</h6>
                {teacherdetails.map((item) => (
                  <>
                    <div style={{ display: "flex" }}>
                      <label
                        className="labelcolor"
                        style={{ width: "15%" }}
                        for="task-title"
                      >
                        Email
                      </label>
                      <label style={{ width: "40%" }}>{item.email}</label>
                    </div>
                    <div style={{ display: "flex" }}>
                      <label
                        className="labelcolor"
                        style={{ width: "15%" }}
                        for="task-title"
                      >
                        Phone
                      </label>
                      <label style={{ width: "40%" }}>{item.phone}</label>
                    </div>
                    <div style={{ display: "flex" }}>
                      <label
                        className="labelcolor"
                        style={{ width: "15%" }}
                        for="task-title"
                      >
                        Email Status
                      </label>
                      <label style={{ width: "40%" }}>{item.status}</label>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className="card col-md-5">
              <div className="card-header">
                <h6 className="mb-2 flex-grow-1">Personal Information</h6>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "7px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <label className="labelcolor" for="task-title">
                      Address
                    </label>
                    <label className="label2">
                      mannna villa Thiruvanamthapuram
                    </label>
                  </div>
                  <div style={{ display: "flex" }}>
                    <label className="labelcolor" for="task-title">
                      City
                    </label>
                    <label className="label2">Thiruvananthapuram</label>
                  </div>
                  <div style={{ display: "flex" }}>
                    <label className="labelcolor" for="task-title">
                      Nationality
                    </label>
                    <label className="label2">United Arab Emirates</label>
                  </div>
                  <div style={{ display: "flex" }}>
                    <label className="labelcolor" for="task-title">
                      Date of Birth
                    </label>
                    <label className="label2">United Arab Emirates</label>
                  </div>
                  <div style={{ display: "flex" }}>
                    <label className="labelcolor" for="task-title">
                      Gender
                    </label>
                    <label className="label2">United Arab Emirates</label>
                  </div>

                  <div style={{ display: "flex" }}>
                    <label className="labelcolor" for="task-title">
                      Status
                    </label>
                    <label className="label2">United Arab Emirates</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row card-div">
            <div className=" card col-md-5">
              <div className="card-header">
                <h6 className="mb-2 flex-grow-1">Professional Information</h6>
                <div style={{ display: "flex" }}>
                  <label className="labelcolor" for="task-title">
                    User Join Date
                  </label>
                  <label className="label2">Thu Apr 27 2023</label>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="labelcolor" for="task-title">
                    Qualification
                  </label>
                  <label className="label2"></label>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="labelcolor" for="task-title">
                    Designation
                  </label>
                  <label className="label2">Professor</label>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="labelcolor" for="task-title">
                    Teacher Curriculum
                  </label>
                  <label className="label2">Mathematicss</label>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="labelcolor" for="task-title">
                    Teacher Class
                  </label>
                  <label className="label2"></label>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="labelcolor" for="task-title">
                    Supervisor Class
                  </label>
                  <label className="label2"></label>
                </div>
              </div>
            </div>
            <div className="card col-md-5">
              <div className="card-header">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Grade And Division</th>
                        <th scope="col">Subject Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {teachersubject.map((item) => {
                        return (
                          <tr>
                            <th scope="row">{item.teachernum}</th>
                            <td>{item.gradeanddivision}</td>
                            <td>{item.subjectname}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Modal Start */}

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-text-wrapper">
            Are you sure you want to delete ?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="delete-btn-wrapper border-top w-100">
            <button
              onClick={() => setShowDeleteModal(false)}
              className="dlt-btn-1"
            >
              Cancel
            </button>
            <button
              onClick={() => setShowDeleteModal(false)}
              type="submit"
              className="dlt-btn-2"
              value="delete"
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal End */}
      {/* Block Modal Start */}

      <Modal
        show={showblockModal}
        onHide={() => setShowBlockModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Block Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-text-wrapper">
            Do you really want to block ?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="delete-btn-wrapper border-top w-100">
            <button
              onClick={() => setShowBlockModal(false)}
              className="dlt-btn-1"
            >
              Cancel
            </button>
            <button
              onClick={() => setShowBlockModal(false)}
              type="submit"
              className="dlt-btn-2"
              value="delete"
            >
              Ok
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Block Modal End */}

      {/* Resign Modal Start */}

      <Modal
        show={showresignModal}
        onHide={() => setShowResignModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Resign Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-text-wrapper">
            Are you sure you want to resign ?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="delete-btn-wrapper border-top w-100">
            <button
              onClick={() => setShowResignModal(false)}
              className="dlt-btn-1"
            >
              Cancel
            </button>
            <button
              onClick={() => setShowResignModal(false)}
              type="submit"
              className="dlt-btn-2"
              value="delete"
            >
              Ok
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Resign Modal End */}
    </>
  );
}

export default TeacherDetailsView;
