import React, { useState } from "react";
import Params from "../../config/Params";
import ApiConfig from "../../api/ApiConfig";
import { simplePostCall } from "../../api/ApiServices";
import { useLocation } from "react-router-dom";

function ChangePassword() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Tid = params.get("message");
  console.log(Tid, "teacherid");

  const [changePasswordValue, setChangePasswordValue] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const ChangePasswordApi = () => {
    let requestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_role: "teacher",
      teacher_id: Tid,
      old_password: changePasswordValue,
      new_password: confirmPassword,
    });
    simplePostCall(ApiConfig.TEACHER_PASSWORD, requestBody)
      .then((data) => {
        console.log(data, "passwordchange");
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  return (
    <div className="row edsys-table">
      <div className="col-sm-12">
        <div className="d-flex">
          <h5 className="mb-2 flex-grow-1">Change Password</h5>
        </div>
        <div className="card">
          <div className="card-header">
            <label
              for="task-title "
              style={{ fontSize: "40px", fontFamily: "poppins" }}
            >
              Ashick
            </label>

            <form
              className="form-bookmark needs-validation"
              id="bookmark-form"
              novalidate=""
            >
              <div className="form-row">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label for="task-title">Old Password</label>
                    <input
                      className="datepicker-here form-control digits"
                      type="password"
                      value={changePasswordValue}
                      onChange={(e) => {
                        setChangePasswordValue(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label for="task-title">Confirm Password</label>
                    <input
                      className="datepicker-here form-control digits"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="schedulebutton">
                  <div></div>
                  <div className="">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => {
                        ChangePasswordApi();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
