import React, { useEffect, useState } from "react";
import moment from "moment";
import active_like_btn from "../../assets/images/MyDiary/active_like_btn.svg";
import like_btn from "../../assets/images/Fourms/like_btn.svg";
import part_1 from "../../assets/images/MyDiary/part_1.svg";
import part_2 from "../../assets/images/MyDiary/part_2.svg";
import part_3 from "../../assets/images/MyDiary/part_3.svg";
import part_4 from "../../assets/images/MyDiary/part_4.svg";
import com_send_btn from "../../assets/images/MyDiary/com_send_btn.svg";
import reply_icon from "../../assets/images/MyDiary/reply_icon.svg";
import filter_icon from "../../assets/images/MyDiary/filter_icon.svg";
import { Link, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import dislike_btn from "../../assets/images/Fourms/dislike_btn.svg";
import com_icon from "../../assets/images/Fourms/com_icon.svg";
import axios from "axios";
import ApiConfig from "../../api/ApiConfig";
import Params from "../../config/Params";
import { io } from "socket.io-client";

const FeedView = () => {
  const [isShown, setIsShown] = useState(false);
  const [showreply, setShowReply] = useState(false);
  const handleshow = () => setShowReply(!showreply);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [feedDetail, setFeedDetail] = useState([]);
  const location = useLocation();
  const item = location.state;
  const [socketData, setSocketData] = useState("");
  const [getFeedDetail, setGetFeedDetail] = useState("");
  const [textInput, setTextInput] = useState("");
  console.log(textInput, "idfrom");
  const [likeStates, setLikeStates] = useState({});
  const [dislikeStates, setDislikeStates] = useState({});
  const [feedbackStates, setFeedbackStates] = useState({});
  useEffect(() => {
    feedDetailsItem();
    getCommentData();
  }, [socketData]);
  const feedDetailsItem = () => {
    console.log("Calling API with item:", item);
    axios(ApiConfig.FEED_VIEW, {
      method: "GET",
      params: {
        feed_id: item,
        user_id: Params.teacher_id,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
        api_key: Params.api_key,
        customer_id: Params.customer_id,
      },
    })
      .then((response) => {
        console.log("API response:", response);
        if (response.data.result) {
          setFeedDetail(response.data.data[0]);
          const newItems = response.data.data;
          setFeedData((prev) => [...prev, ...newItems]);
          parseData(response.data.data);
        }
      })
      .catch((error) => {
        console.error("API call error:", error);
      });
  };
  const parseData = (data) => {
    console.log(" givendata ", data);

    // console.log(" given ", obj);

    // setFeed_comment_count(data[0].feed_comment_count);
    // setFeed_dislike_count(data[0].feed_dislike_count);
    // setFeed_like_count(data[0].feed_like_count);
    setLikee(data[0].feed_liked);
    setDisLike(data[0].feed_disliked);
    setFeed_like_count(data[0].feed_like_count);
    setFeed_dislike_count(data[0].feed_dislike_count);
    // setDescriptionList(obj);
  };

  const [dislike_count, setFeed_dislike_count] = useState("");
  const [like_count, setFeed_like_count] = useState("");
  const [commentData, setCommentData] = useState([]);
  const [loading, setLoading] = useState("");
  const [pageNo, setpageNo] = useState("");
  const getCommentData = async () => {
    // console.log("new data", feed_id);
    let requestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      feed_id: item,
      // page_no: "",
      // page_limit: "",
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    };

    axios
      .post(
        "https://app.edsys.in:3000/api/teacher/feed/listComment",
        requestBody,
        { headers },
      )
      .then((response) => {
        console.log("check123", response.data);
        if (response.data.result) {
          console.log("no way ouy", response.data.data);
          // setCommentData((prevData) => [...prevData, ...response.data.data]);
          setCommentData((prevdata) => {
            const uniqueData = response.data.data.filter(
              (item) =>
                !prevdata.some((prevItem) => prevItem.feed_id === item.feed_id),
            );
            return [...prevdata, ...uniqueData];
          });
          setpageNo(response.data.next_page);
          setLoading(false);
          setTotalcomment(response.data.data);
        } else {
          console.log("no comment", response.data);
          setLoading(false);
        }
        // setisLoading(false);
      })
      .catch((error) => {
        // setisLoading(false);
        console.log("home error4: ", error);
      });
  };
  const [totalComment, setTotalcomment] = useState([]);
  const customer_id = Params.customer_id;
  const user_id = Params.teacher_id;
  const socket = io(
    ApiConfig.NODE_URL + "?customerId=" + customer_id + "&userId=" + user_id,
  );

  // const handleFocus = async () => {
  //   socket.on("feed_like_count", (value) => {
  //     if (value.result) {
  //       console.log("like received", value);
  //       setLike(true);
  //       setData((prevData) => {
  //         if (prevData.feed_id === value.feed_id) {
  //           return {
  //             ...prevData,
  //             feed_like_count: value.feed_like_count,
  //             feed_dislike_count: value.feed_dislike_count,
  //             feed_liked: value.feed_liked,
  //             feed_disliked: value.feed_disliked,
  //           };
  //         }
  //         return prevData;
  //       });
  //     }
  //   });
  //   socket.on("rec_feed_comment", (value) => {
  //     handleMessage(value);
  //   });

  //   // Emit the 'room' event once the socket connection is established
  //   socket.on("connect", async () => {
  //     console.log("Socket Connected Successfully");
  //     const messageData = {
  //       room_id: Params.teacher_id,
  //     };

  //     // Emit the 'room' event with the message data
  //     socket.emit("feedRoom", messageData);
  //   });
  // };
  const handleMessage = (data) => {
    console.log(data, "response from so", data.comment_parrent_comment_id);
    if (
      data.comment_parrent_comment_id === null ||
      data.comment_parrent_comment_id === ""
    ) {
      let arr = [data];
      arr = arr.map((item) => ({
        ...item,
        //viewChange: false,
        // comment_comments: []
      }));

      let mergedArray = [...arr, ...commentData];
      console.log("mergedArray", mergedArray);
      setCommentData(mergedArray);
    } else {
      console.log("else part.....");

      addToComments(commentData, data.comment_parrent_comment_id, data);
    }
  };
  console.log(commentData, "comment data");
  useEffect(() => {
    setFeedData([]);
    const customer_id = Params.customer_id;
    const user_id = Params.teacher_id;
    const socket = io(
      ApiConfig.NODE_URL + "?customerId=" + customer_id + "&userId=" + user_id,
    );
    socket.on("connect", () => {
      console.log("connected on feed view");
      socket.emit("feedRoom", () => {
        console.log("connect to feedRoom");
      });
    });
    socket.on("rec_feed_comment", (data) => {
      console.log("Received event from socket data:", data);
      handleMessage(data);
      setSocketData(data);
    });
    socket.on("disconnect", (reason) => {
      console.log("Socket disconnected:", reason);
    });
    return () => {
      socket.disconnect();
      socket.removeAllListeners();
      socket.close();
      console.log("Socket disconnected from comment section");
    };
  }, [commentData]);
  const findParentComment = (comments, parentCommentId) => {
    console.log(comments, parentCommentId, "finding to the parent comment");

    for (let comment of comments) {
      console.log("entering the first condition");

      if (comment.comment_id == parentCommentId) {
        console.log("rrrr", parentCommentId, comment);
        // setGetFeedDetail((prevState) => [comment, ...prevState]);
        return comment;
      } else if (
        comment.comment_comments &&
        comment.comment_comments.length > 0
      ) {
        console.log(
          "sub loop",
          comment.comment_comments,
          parentCommentId,
          comment,
        );
        const parentComment = findParentComment(
          comment.comment_comments,
          parentCommentId,
        );
        console.log("parent.......", parentComment);

        if (parentComment) {
          return parentComment;
        }
      }
    }
    return null;
  };
  useEffect(() => {
    feedDetailsItem();
  }, []);
  function addToComments(comments, parentCommentId, newComment) {
    console.log("enter the addtoComments....", newComment);

    const parentComment = findParentComment(comments, parentCommentId);
    if (parentComment) {
      console.log(parentComment, "parent");

      if (!parentComment.comment_comments) {
        parentComment.comment_comments = []; // Initialize comment_comments if not exists
      }
      console.log("hiuukuu");
      const childComment = {
        ...newComment,
        comment_comments: [], // Initialize an empty array for child comments
      };

      parentComment.comment_comments.push(childComment); // Add the new comment as a child

      const commentListCopy = [...comments];
      const parentCommentIndex = commentListCopy.findIndex(
        (comment) => comment.comment_id === parentCommentId,
      );
      if (parentCommentIndex !== -1) {
        commentListCopy[parentCommentIndex] = parentComment;
        console.log("last", parentComment);
        setCommentData(commentListCopy);
      }
    }
  }
  const [like, setLike] = useState([]);
  const [data, setData] = useState([]);
  const [sendReplayText, setSendReplayText] = useState("");
  const [sendReplayTextSub, setSendReplayTextSub] = useState("");
  const sendReplay = (comment_id, index) => {
    console.log(comment_id, "comment id.....");

    const eventData = {
      comment_text: sendReplayText || sendReplayTextSub,
      comment_user_id: Params.teacher_id,
      feed_id: item,
      user_role: "teacher",
      parent_comment_id: comment_id,
    };
    socket.emit("feed_comment", eventData, () => {
      console.log("socket emitted....");
    });
    setSendReplayText("");
    setSendReplayTextSub("");
  };
  const sendReplayy = (comment_id, index) => {
    console.log(comment_id, "comment id.....");

    const eventData = {
      comment_text: sendReplayTextt || sendReplayTextSub,
      comment_user_id: Params.teacher_id,
      feed_id: item,
      user_role: "teacher",
      parent_comment_id: comment_id,
    };
    socket.emit("feed_comment", eventData, () => {
      console.log("socket emitted....");
    });
    setSendReplayText("");
    setSendReplayTextSub("");
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      emitSocketEvent();
    }
  };
  const emitSocketEvent = () => {
    if (textInput.trim().length === 0) {
      alert("Enter Message");
      return;
    }
    const eventData = {
      comment_text: textInput,
      comment_user_id: Params.teacher_id,
      feed_id: item,
      user_role: "teacher",
    };

    socket.emit("feed_comment", eventData, () => {
      console.log("socket emitted....");
    });
    setTextInput("");
  };
  const [sockett, setSocket] = useState("");
  const [feedData, setFeedData] = useState([]); // Array to hold feed items

  useEffect(() => {
    // const socketCall = () => {
    const CustomerId = Params.customer_id;
    const userId = Params.teacher_id;
    const socket = io(
      ApiConfig.NODE_URL + "?customerId=" + CustomerId + "&userId=" + userId,
    );
    setSocket(socket);
    socket.on("connect", () => {
      console.log("connected on feed");

      socket.emit("feedRoom", () => {
        console.log("connected on feed_room");
      });
      socket.on("feed_like_count", (data) => {
        console.log("Received event in data:", data);

        if (data.result == true) {
          // setFeedLike(data);

          updateTempArray(data);
        }
      });
      socket.on("newfeed", (data) => {
        console.log("newFeed received", data);
      });
    });
    socket.on("disconnect", (reason) => {
      console.log("Socket disconnected:", reason);
    });

    // Cleanup on component unmount
    return () => {
      if (socket) {
        socket.disconnect();
        console.log("Socket manually disconnected");
      }
    };
  }, []);
  const [likee, setLikee] = useState("");
  const [disLike, setDisLike] = useState("");
  const [comment, setComment] = useState("");
  const updateTempArray = (data) => {
    setLikee(data.feed_liked);
    setDisLike(data.feed_disliked);
    setFeed_like_count(data.feed_like_count);
    setFeed_dislike_count(data.feed_dislike_count);
  };

  const addLikeEvent = (feedId) => {
    console.log(`Liked feed with ID: ${feedId}`);
    // Add your like logic here
    const eventData = {
      like_dislike: true,
      user_role: "teacher",
      feed_id: feedId,
    };

    socket.emit("feed_like", eventData, (data) => {
      console.log("socket emitted....", data);
    });
  };

  const disLikeEvent = (feedId) => {
    console.log(`Disliked feed with ID: ${feedId}`);
    // Add your dislike logic here
    const eventData = {
      like_dislike: false,
      user_role: "teacher",
      feed_id: feedId,
    };

    socket.emit("feed_like", eventData, (data) => {
      console.log("socket emitted....", data);
    });
  };
  const formattedDate = moment(feedDetail?.feed_date).format("DD-MM-YYYY");
  console.log(feedDetail?.feed_date, "like");
  const renderAttachment = () => {
    if (feedDetail.feed_attachment && feedDetail.feed_attachment[0]) {
      const file = feedDetail.feed_attachment[0];
      const fileUrl = `${ApiConfig.NODE_URL}/${file.attachment_feed_file}`;

      switch (file.attachment_type) {
        case "video":
          return (
            <video width="400" controls src={fileUrl}>
              Your browser does not support the video tag.
            </video>
          );
        case "image":
          return <img src={fileUrl} alt="Attachment" width="400" />;
        case "pdf":
          return (
            <iframe
              src={fileUrl}
              width="400"
              height="500"
              title="PDF Attachment"
            >
              Your browser does not support PDFs.
            </iframe>
          );
        case "audio":
          return (
            <audio controls>
              <source src={fileUrl} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          );
        default:
          return (
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
              Download Attachment
            </a>
          );
      }
    }
    return null;
  };

  const [visibleReplyBox, setVisibleReplyBox] = useState(null); // Track the visible reply box
  const [visibleReplyBoxx, setVisibleReplyBoxx] = useState(null);
  const [sendReplayTextt, setSendReplayTextt] = useState("");
  // const [sendReplayTexttt, setSendReplayTextt] = useState("");
  const handleReplyClick = (index) => {
    // Toggle the reply box for the clicked button
    setVisibleReplyBox((prev) => (prev === index ? null : index));
  };
  // const handleReplyClickk = (indx) => {
  //   // Toggle the reply box for the clicked button
  //   setVisibleReplyBoxx((prev) => (prev === indx ? null : indx));
  // };

  const handleReplyClickk = (indx) => {
    setVisibleReplyBoxx(indx === visibleReplyBoxx ? null : indx); // Toggle visibility
  };

  return (
    <>
      <div class="Note_view card p-4">
        <div className="main-section">
          <div className="row">
            <div className="col-md-12">
              <div className="comments-wrapper ">
                <div className="single-comment com-border-bottom">
                  <div className="com-img-box">
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                      class="flex-grow-0"
                      src={
                        ApiConfig.NODE_URL + "/" + feedDetail?.user_profile_pic
                      }
                      alt=""
                    />
                  </div>
                  <div className="text-wrapper">
                    <div className="com-heading-wrapper">
                      <p className="title">{feedDetail?.user_name}</p>
                    </div>
                    <div className="time-and-like">
                      <div className="time">{feedDetail?.feed_title}</div>
                    </div>
                    <div className="comment-text">
                      <p>{feedDetail?.feed_description}</p>
                    </div>
                    <div className="reply-btn-wrapper">
                      <span>
                        {formattedDate},{feedDetail?.feed_time}
                      </span>
                    </div>
                    <div>{renderAttachment()}</div>
                    <div className="d-flex align-items-center ml-3">
                      <div
                        className="d-flex align-items-center ml-3"
                        style={{ paddingTop: "5px" }}
                      >
                        <div
                          key={feedDetail.feed_id}
                          className="feed-item"
                          style={{ display: "flex" }}
                        >
                          <div
                            key={feedDetail.feed_id}
                            className="feed-item"
                            style={{ display: "flex" }}
                          >
                            <div className="like-btn align-items-center">
                              <i
                                className={
                                  like
                                    ? "bi bi-hand-thumbs-up-fill"
                                    : "bi bi-hand-thumbs-up"
                                }
                                onClick={() => addLikeEvent(feedDetail.feed_id)}
                                style={{
                                  color: "#78266a",
                                  cursor: "pointer",
                                }}
                              ></i>
                              <span className="margin-left">{like_count}</span>
                            </div>
                            <div className="dislike-btn align-items-center margin-left">
                              <i
                                className={
                                  disLike
                                    ? "bi bi-hand-thumbs-down-fill"
                                    : "bi bi-hand-thumbs-down"
                                }
                                onClick={() => disLikeEvent(feedDetail.feed_id)}
                                style={{
                                  color: "#78266a",
                                  cursor: "pointer",
                                }}
                              ></i>
                              <span className="margin-left">
                                {dislike_count}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="comments-btn  align-items-center margin-left">
                        <Link to="/FeedView">
                          <img src={com_icon} alt="" />
                        </Link>
                        <span className="margin-left">
                          {feedDetail?.feed_comment_count} comments
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="main-comment-input-wrapper"
                // style={{ height: "1px", background: "green" }}
              >
                <Form>
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Control
                      value={textInput}
                      onKeyPress={(event) => handleKeyPress(event)}
                      onChange={(e) => {
                        setTextInput(e.target.value);
                        console.log(e.target.value, "value");
                      }}
                      type="text"
                      placeholder="Write Comment…"
                    />
                    <img src={com_send_btn} alt="" onClick={emitSocketEvent} />
                  </Form.Group>
                </Form>
              </div>
            </div>

            <div className="comments-wrapper col-md-12">
              <div className="single-comment com-border-bottom"></div>
              <p className="title">Comments</p>
              {commentData.map((item, index) => (
                <div className="single-comment mt-3" key={index}>
                  <div className="text-wrapper">
                    <div className="com-heading-wrapper">
                      <div style={{ display: "flex", gap: "15px" }}>
                        <img
                          src={
                            ApiConfig.NODE_URL +
                            "/" +
                            item?.comment_user_profile_pic
                          }
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "100%",
                          }}
                        />
                        <p
                          className="title"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {item.comment_user_name}
                        </p>
                      </div>
                      <div className="time-and-like">
                        <div className="time">
                          {
                            new Date(item.comment_date)
                              .toISOString()
                              .split("T")[0]
                          }
                          , {item.comment_time}
                        </div>
                      </div>
                    </div>
                    <div className="comment-text">
                      <p>{item.comment_text}</p>
                    </div>
                    <div className="item-wrapper">
                      <div
                        className="reply-btn-wrapper"
                        onClick={() =>
                          setVisibleReplyBox(
                            visibleReplyBox === index ? null : index,
                          )
                        }
                      >
                        <img src={reply_icon} alt="Reply Icon" />
                        <span>Reply</span>
                      </div>
                      {visibleReplyBox === index && (
                        <div
                          className="reply-input-wrapper"
                          style={{ display: "flex", gap: "5px" }}
                        >
                          <input
                            type="text"
                            placeholder="Type your reply..."
                            className="reply-input"
                            style={{
                              display: "flex",
                              width: "100%",
                              border: "none",
                              padding: "10px",
                              borderRadius: "10px",
                            }}
                            onChange={(e) => setSendReplayText(e.target.value)}
                            value={sendReplayText}
                          />

                          <i
                            className="bi bi-send"
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => sendReplay(item.comment_id)}
                          />
                        </div>
                      )}
                    </div>

                    {/* Nested Comments */}
                    <div className="teachers-reply">
                      {item.comment_comments.map((subItem, indx) => {
                        const uniqueKey = `${index}-${indx}`; // Create a unique key for nested reply boxes
                        return (
                          <div
                            className="single-comment mt-3"
                            style={{ paddingLeft: "25px" }}
                            key={uniqueKey}
                          >
                            <div className="com-img-box">
                              <img
                                src={
                                  ApiConfig.NODE_URL +
                                  "/" +
                                  subItem?.comment_user_profile_pic
                                }
                                alt=""
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "100%",
                                }}
                              />
                            </div>
                            <div className="text-wrapper">
                              <div className="com-heading-wrapper">
                                <p className="title">
                                  {subItem.comment_user_name}
                                </p>
                                <div className="time-and-like">
                                  <div className="time">
                                    {
                                      new Date(subItem.comment_date)
                                        .toISOString()
                                        .split("T")[0]
                                    }
                                    , {subItem.comment_time}
                                  </div>
                                </div>
                              </div>
                              <div className="comment-text">
                                <p>{subItem.comment_text}</p>
                              </div>

                              <div className="item-wrapper">
                                <div
                                  className="reply-btn-wrapper"
                                  onClick={() =>
                                    setVisibleReplyBoxx(
                                      visibleReplyBoxx === uniqueKey
                                        ? null
                                        : uniqueKey,
                                    )
                                  }
                                >
                                  <img src={reply_icon} alt="Reply Icon" />
                                  <span>Reply</span>
                                </div>
                                {visibleReplyBoxx === uniqueKey && (
                                  <div
                                    className="reply-input-wrapper"
                                    style={{ display: "flex", gap: "5px" }}
                                  >
                                    <input
                                      type="text"
                                      placeholder="Type your reply..."
                                      className="reply-input"
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        border: "none",
                                        padding: "10px",
                                        borderRadius: "10px",
                                      }}
                                      onChange={(e) =>
                                        setSendReplayTextt(e.target.value)
                                      }
                                      value={sendReplayTextt}
                                    />
                                    <i
                                      className="bi bi-send"
                                      style={{
                                        display: "flex",
                                        justifyContent: "end",
                                        alignItems: "center",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        sendReplayy(subItem.comment_id)
                                      }
                                    />
                                  </div>
                                )}
                                <div className="teachers-reply">
                                  {subItem.comment_comments.map(
                                    (subItemm, indx) => {
                                      const uniqueKey = `${index}-${indx}`; // Create a unique key for nested reply boxes
                                      return (
                                        <div
                                          className="single-comment mt-3"
                                          style={{ paddingLeft: "25px" }}
                                          key={uniqueKey}
                                        >
                                          <div className="com-img-box">
                                            <img
                                              src={
                                                ApiConfig.NODE_URL +
                                                "/" +
                                                subItemm?.comment_user_profile_pic
                                              }
                                              alt=""
                                              style={{
                                                width: "40px",
                                                height: "40px",
                                                borderRadius: "100%",
                                              }}
                                            />
                                          </div>
                                          <div
                                            className="text-wrappe"
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              width: "100%",
                                            }}
                                          >
                                            <div className="com-heading-wrapp">
                                              <p
                                                className="title"
                                                style={{
                                                  color: "#282739",
                                                  fontSize: "13px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {subItemm.comment_user_name}
                                              </p>
                                              <div className="comment-text">
                                                <p>{subItemm.comment_text}</p>
                                              </div>
                                            </div>
                                            <div className="time-and-like">
                                              <div className="tim">
                                                <p
                                                  className="title"
                                                  style={{
                                                    color:
                                                      "rgba(40, 39, 57, 0.5)",
                                                    fontSize: "13px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  {
                                                    new Date(
                                                      subItemm.comment_date,
                                                    )
                                                      .toISOString()
                                                      .split("T")[0]
                                                  }
                                                  ,{subItemm.comment_time}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    },
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Sight Modal Start */}
      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="common-filter-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="title-wrapper">
              <p>Sight</p>
              <p>25/40</p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="title">
            <p>See By</p>
            <p>25</p>
          </div>
          <div className="list-wrapper">
            <div className="list-item">
              <img src={part_4} alt="" />
              <div className="text-box">
                <p className="main">Parent Name</p>
                <p className="sub">Mon 21, 2023 0:00 AM</p>
              </div>
            </div>
            <div className="list-item">
              <img src={part_3} alt="" />
              <div className="text-box">
                <p className="main">Parent Name</p>
                <p className="sub">Mon 21, 2023 0:00 AM</p>
              </div>
            </div>
            <div className="list-item">
              <img src={part_1} alt="" />
              <div className="text-box">
                <p className="main">Parent Name</p>
                <p className="sub">Mon 21, 2023 0:00 AM</p>
              </div>
            </div>
            <div className="list-item">
              <img src={part_1} alt="" />
              <div className="text-box">
                <p className="main">Parent Name</p>
                <p className="sub">Mon 21, 2023 0:00 AM</p>
              </div>
            </div>
          </div>
          <div className="title mt-2">
            <p>Remaining</p>
            <p>15</p>
          </div>
          <div className="list-wrapper">
            <div className="list-item">
              <img src={part_4} alt="" />
              <div className="text-box">
                <p className="main">Parent Name</p>
                <p className="sub">Mon 21, 2023 0:00 AM</p>
              </div>
            </div>
            <div className="list-item">
              <img src={part_3} alt="" />
              <div className="text-box">
                <p className="main">Parent Name</p>
                <p className="sub">Mon 21, 2023 0:00 AM</p>
              </div>
            </div>
            <div className="list-item">
              <img src={part_1} alt="" />
              <div className="text-box">
                <p className="main">Parent Name</p>
                <p className="sub">Mon 21, 2023 0:00 AM</p>
              </div>
            </div>
            <div className="list-item">
              <img src={part_1} alt="" />
              <div className="text-box">
                <p className="main">Parent Name</p>
                <p className="sub">Mon 21, 2023 0:00 AM</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Sight Modal End */}
    </>
  );
};
export default FeedView;
