import React, { useState, useEffect } from "react";
import ApiConfig from "../../../api/ApiConfig";
import { multipartPostCall, simplePostCall } from "../../../api/ApiServices";
import Params from "../../../config/Params";
import { toast } from "react-toastify";
import SpinnerCmp from "../../../components/SpinnerCmp";
import { useTranslation } from "react-i18next";

const toastOptions = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const AddEditAnnouncement = (props) => {
  const { t } = useTranslation();

  let editModelData =
    props.data && props.data.mode !== "add" ? props.data.editData : {};
  const [state, setState] = useState({
    type:
      Object.keys(editModelData).length > 0
        ? editModelData.announcement_type
        : "announcement",
    content:
      Object.keys(editModelData).length > 0
        ? editModelData.announcement_content
        : "",
    announcement_file: "",
    announcement_content_type:
      Object.keys(editModelData).length > 0
        ? editModelData.announcement_content_type
        : "text",
    announcement_id:
      Object.keys(editModelData).length > 0 ? editModelData.announcement_id : 0,
  });

  const [filebase64, setFilebase64] = useState("");
  const [loading, setLoading] = useState(false);
  const types = ["alert", "announcement", "emergency", "info", "message"];
  const content_type = ["Text", "Image", "PDF", "Audio"];

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const onInputChangeHandle = (e) => {
    const { name, value, files } = e.target;
    if (name === "announcement_file") {
      e.target.files[0].then((data) => {
        setFilebase64(data);
      });
    }
    setState({ ...state, [name]: value });
    toast.dismiss();
  };
  const displayStyle = {
    display: "block",
  };
  const displayFormStyle = {
    display: loading ? "none" : "block",
  };
  const onSubmitHandle = (e) => {
    setLoading(true);
    // toast.loading("processing..", toastOptions);
    let obj = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      content: state.content,
      type: state.type,
      announcement_content_type: state.announcement_content_type,
    };
    let apiUri = "";
    if (state.announcement_id > 0) {
      obj = {
        ...obj,

        announcement_id: state.announcement_id,
      };
      apiUri = ApiConfig.ANNOUNCEMENT_EDIT_API;
    } else {
      obj = {
        ...obj,
        announcement_file: state.type === "text" ? "" : filebase64,
      };
      apiUri = ApiConfig.ANNOUNCEMENT_ADD_API;
    }

    simplePostCall(apiUri, JSON.stringify(obj))
      .then((data) => {
        if (data.result) {
          toast.success(data.message, toastOptions);
          props.onClose(true);
        } else {
          setLoading(false);
          toast.success(data.message, toastOptions);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong. please try again!", toastOptions);
        console.log("api response", error);
      });
  };
  const onSubmitHandle2 = (e) => {
    setLoading(true);

    // Create a new FormData object
    let formData = new FormData();

    // Append key-value pairs to FormData
    formData.append("api_key", Params.api_key);
    formData.append("customer_id", Params.customer_id);
    formData.append("user_id", Params.teacher_id);
    formData.append("content", state.content);
    formData.append("type", state.type);
    formData.append(
      "announcement_content_type",
      state.announcement_content_type,
    );

    let apiUri = "";

    if (state.announcement_id > 0) {
      // For editing an existing announcement
      formData.append("announcement_id", state.announcement_id);
      apiUri = ApiConfig.ANNOUNCEMENT_EDIT_API;
    } else {
      console.log(state.type, "hoiiiiiiiiiooooo");

      // For adding a new announcement
      if (state.type === "text") {
        formData.append("announcement_image", ""); // No file if it's just text
      } else if (state.type == "image") {
        formData.append("announcement_image", filebase64); // Append file for non-text types
      } else {
        formData.append("announcement_image", filebase64);
      }
      apiUri = ApiConfig.ANNOUNCEMENT_ADD_API;
    }

    // Perform the API call using FormData as payload
    multipartPostCall(apiUri, formData)
      .then((data) => {
        if (data.result) {
          toast.success(data.message, toastOptions);
          props.onClose(true);
        } else {
          setLoading(false);
          toast.success(data.message, toastOptions);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong. Please try again!", toastOptions);
        console.log("API response", error);
      });
  };

  useEffect(() => {}, []);

  return (
    <div
      show
      class="modal fade show testingll"
      style={displayStyle}
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md" role="document">
        <SpinnerCmp loading={loading} />
        <div class="modal-content" style={displayFormStyle}>
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {props.data.mode === "view"
                ? "View"
                : props.data.mode === "edit"
                ? `${t("Edit")}`
                : `${t("Create")}`}{" "}
              {t("Announcement")}
            </h5>
            <button
              class="btn-close"
              onClick={props.onClose}
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              {" "}
            </button>
          </div>
          <div class="modal-body datetime-picker modal-scroll">
            <form
              class="form-bookmark needs-validation"
              id="bookmark-form"
              novalidate=""
            >
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="task-title">{t("Content")}</label>
                  <input
                    class="form-control"
                    id="task-title"
                    type="text"
                    readOnly={props.data.mode === "view"}
                    required=""
                    autocomplete="off"
                    name="content"
                    value={state.content}
                    onChange={onInputChangeHandle}
                  />
                </div>

                <div className="form-group col-md-12">
                  <label for="task-title">{t("Type")}</label>
                  <select
                    //   className="js-example-basic-single form-select"
                    className="form-select"
                    name="type"
                    onChange={onInputChangeHandle}
                    value={state.type}
                    disabled={props.data.mode !== "add"}
                  >
                    {types.length > 0
                      ? types.map((type, index) => {
                          return (
                            <option key={index} value={type.toLowerCase()}>
                              {type}
                            </option>
                          );
                        })
                      : null}
                  </select>
                  {/* <div className="row">
                    {types.map((type, index) => {
                      return (
                        <div className="col-md-2">
                          <div className="radio-button">
                            <div className="inputGroup">
                              <input
                                id={"radio" + index}
                                name="type"
                                value={type.toLowerCase()}
                                checked={state.type === type.toLowerCase()}
                                onChange={onInputChangeHandle}
                                type="radio"
                                readOnly={props.data.mode === "view"}
                              />
                              <label
                                style={{ marginLeft: "5px" }}
                                for={"radio" + index}
                              >
                                {type}
                              </label>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div> */}
                </div>
                <div className="form-group col-md-12">
                  <label for="task-title">{t("Content Type")}</label>
                  <select
                    //   className="js-example-basic-single form-select"
                    className="form-select"
                    name="announcement_content_type"
                    onChange={onInputChangeHandle}
                    defaultValue={state.announcement_content_type}
                    disabled={props.data.mode !== "add"}
                  >
                    {content_type.length > 0
                      ? content_type.map((type, index) => {
                          return (
                            <option key={index} value={type.toLowerCase()}>
                              {type}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
                {state.announcement_content_type !== "text" &&
                props.data.mode === "add" ? (
                  <div class="form-group col-md-12">
                    <label for="task-title">{t("Upload File")}</label>
                    {/* <input
                      class="form-control"
                      type="file"
                      name="announcement_file"
                      value={state.announcement_file}
                      onChange={onInputChangeHandle}
                      readOnly={props.data.mode === "view"}
                    /> */}
                    <input
                      className="form-control"
                      type="file"
                      multiple
                      onChange={() => onInputChangeHandle()}
                    />
                  </div>
                ) : null}
              </div>
            </form>
          </div>
          <div class="modal-footer">
            {props.data.mode !== "view" ? (
              <button
                class="btn btn-secondary"
                id="Bookmark"
                type="submit"
                onClick={onSubmitHandle2}
              >
                {t("save")}
              </button>
            ) : null}
            <button
              class="btn btn-primary"
              type="button"
              onClick={props.onClose}
              data-bs-dismiss="modal"
            >
              {props.data.mode === "view" ? `${"Close"}` : `${"Close"}`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditAnnouncement;
